































































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_setting_user, patch_setting_user } from "@/api";
import { settingUserDetailType, settingUserType } from "@/api/api.types";
import PhotoAvatar from "@/components/PhotoAvatar.vue";
import UserAdd from "./UserAdd.vue";
import subDomain from "@/shared/init";

@Component({ name: "user-index", components: { PhotoAvatar, UserAdd } })
export default class UserIndex extends Vue {
  /* Variables */

  /* Check if subdomain is commissary */
  isCommissary = subDomain === "commissary.";

  tableData = [] as settingUserType["data"]["_embedded"]["profile"];

  tableFilter = {
    search_name: this.$route.query.search_name || null,
    is_kkip: this.$route.query.is_kkip
      ? parseInt(this.$route.query.is_kkip as string)
      : null,
  };

  loading = false;

  addUser = null as unknown as boolean | settingUserDetailType["data"];

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  kkipVisibleList = [
    { value: null, text: "Show All User" },
    { value: 1, text: "Show Only KKIP User" },
    { value: 0, text: "Show Only Non-KKIP User" },
  ];

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "firstName" },
      { text: "Photo", value: "photo" },
      { text: "Username", value: "username" },
      // { text: "IC Number", value: "icNumber" },
      { text: "Employee ID", value: "staffId" },
      { text: "Position", value: "position" },
      // { text: "Phone", value: "phone" },
      { text: "Role", value: "role" },
      { text: "Active", value: "isActive" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_setting_user({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.profile;
      this.page = { page, page_count, page_size };

      route_replace(this.$route.path, {
        ...this.tableFilter,
        is_kkip: this.tableFilter.is_kkip?.toString(),
        page: toPage,
      });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async onActiveChange(uuid: string, isActive: number): Promise<void> {
    try {
      await patch_setting_user(uuid, { isActive });
      const notif = isActive == 1 ? "Activated" : "Deactivated";
      Notif.notif_success("User " + notif);
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
