
















































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace, time_format } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_device_locations } from "@/api";
import { deviceLocationsType, devLocalDetailType } from "@/api/api.types";

const DevLocalAdd = () => import("./DevLocalAdd.vue");

type formDevLocal = {
  uuid: string;
  name: string;
  telegramChatId: string | null;
  note: string | null;
};

@Component({ name: "device-local-index", components: { DevLocalAdd } })
export default class DevLocalIndex extends Vue {
  /* Constants */

  time_format = time_format;

  /* Variables */

  tableData = [] as deviceLocationsType["data"]["_embedded"]["deviceLocation"];

  tableFilter = {
    name: this.$route.query.name || null,
  };

  loading = false;

  devLocalData = false;

  devLocalEdit = null as unknown as formDevLocal;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  edit = "";

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Telegram ID", value: "telegramChatId" },
      { text: "Created At", value: "createdAt" },
      { text: "Note", value: "note" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_device_locations({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.deviceLocation;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  doEdit(item: devLocalDetailType["data"]): void {
    const { uuid, name, telegramChatId, note } = item;
    this.devLocalEdit = {
      uuid: uuid,
      name: name,
      telegramChatId: telegramChatId,
      note: note,
    };
    this.devLocalData = true;
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
