

























































































































































































































































































































































import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { doInterval, open_export, route_replace } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import {
  get_report_attendance,
  get_setting_department,
  get_setting_shift,
} from "@/api";
import {
  reportAttendanceType,
  settingDepartmentType,
  settingShiftType,
} from "@/api/api.types";
import DatePicker from "@/components/DatePicker.vue";
import ButtonTool from "@/components/ButtonTool.vue";
import URL from "@/api/api.constants";
import ExportFilter from "./ExportFilter.vue";
import TempText from "@/components/TempText.vue";
import { __await } from "tslib";

@Component({
  name: "attendance-report",
  components: { DatePicker, ButtonTool, ExportFilter, TempText },
})
export default class AttendanceReport extends Vue {
  /* Variables */

  tableData = [] as reportAttendanceType["data"]["attendance"];

  closeOnContentClick = false;
  closeOnContentExcelClick = false;

  typeExport = "";

  tableFilter = {
    date: this.$route.query.start_date || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    department_uuid: this.$route.query.department_uuid || undefined,
    shift_uuid: this.$route.query.shift_uuid || null,
    type: this.$route.query.type || null,
    name: this.$route.query.name || null,
  };

  exportOption = ["name", "timeIn", "timeOut", "late"];

  date = this.$route.query.start_date
    ? [this.$route.query.start_date, this.$route.query.end_date]
    : [];

  departmentList =
    [] as settingDepartmentType["data"]["_embedded"]["department"];

  shiftList = [] as settingShiftType["data"]["_embedded"]["shift"];

  typeList = ["Person", "Weekly"];

  loading = false;

  csvFilter = false;

  page = {
    page_count: 0,
    page_size: -1,
    page: 1,
  };

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Department", value: "department" },
      { text: "Location In - Out", value: "locationIn" },
      { text: "Time In", value: "timeIn" },
      { text: "Temp In", value: "temperatureIn" },
      { text: "Time Out", value: "timeOut" },
      { text: "Temp Out", value: "temperatureOut" },
      { text: "Late (minute)", value: "late" },
      { text: "Overtime (minute)", value: "over" },
      { text: "Shift", value: "shift.name" },
      { text: "Summeray Date", value: "summaryDate", groupable: true },
    ];
  }

  /* Methods */

  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      const resp = await get_report_attendance({
        ...this.tableFilter,
        ascending: 1,
      });
      this.tableData = resp.data.attendance;
      route_replace(this.$route.path, this.tableFilter);
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchDepartment(): Promise<void> {
    try {
      const resp = await get_setting_department({ limit: -1 });
      this.departmentList = resp.data._embedded.department;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchShift(): Promise<void> {
    try {
      const resp = await get_setting_shift({ limit: -1 });
      this.shiftList = resp.data._embedded.shift;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  exportTo(type: string): void {
    const filter = {
      ...this.tableFilter,
      fields: type == "csv" ? this.exportOption.toString() : undefined,
    };
    open_export(`${URL.ATTENDANCE}.export-${type}`, filter);
  }

  exportDailyPdf(type: string): void {
    const date = this.tableFilter.date;
    const department_uuid = this.tableFilter.department_uuid;
    let filter = {
      date,
      department_uuid,
    };
    if (!department_uuid) delete filter.department_uuid;
    open_export(`${URL.ATTENDANCE}.export-${type}`, filter);
  }

  exportDateRange(type: string): void {
    const department_uuid = this.tableFilter.department_uuid;
    let filter = {
      ...this.tableFilter,
    };
    if (!department_uuid) delete filter.department_uuid;
    open_export(`${URL.ATTENDANCE}.export-${type}`, filter);
  }

  exportByPerson(type: string): void {
    const department_uuid = this.tableFilter.department_uuid;
    let filter = {
      ...this.tableFilter,
    };
    if (!department_uuid) delete filter.department_uuid;
    open_export(`${URL.ATTENDANCE}.export-pdf-date-range-${type}`, filter);
  }

  exportMonthlyPdf(type?: string): void {
    const month = this.tableFilter.start_date?.toString().substring(5, 7);
    const year = this.tableFilter.start_date?.toString().substring(0, 4);
    const department_uuid = this.tableFilter.department_uuid;

    let filter = {
      month,
      year,
      department_uuid,
    };
    if (!department_uuid) delete filter.department_uuid;
    open_export(`${URL.ATTENDANCE}.export-${type}`, filter);
  }

  exportMonthlyExcel(type?: string): void {
    const month = this.tableFilter.start_date?.toString().substring(5, 7);
    const year = this.tableFilter.start_date?.toString().substring(0, 4);
    const department_uuid = this.tableFilter.department_uuid;
    const filter = {
      monthyear: `${month}-${year}`,
      department_uuid,
    };
    open_export(`${URL.ATTENDANCE}.export-${type}`, filter);
  }

  clearForm(event: string): void {
    this.typeExport = event;
    this.tableFilter.date = "";
    this.tableFilter.start_date = "";
    this.tableFilter.end_date = "";
    this.tableFilter.department_uuid = "";
    this.tableFilter.shift_uuid = "";
    this.tableFilter.type = "";
    this.tableFilter.name = "";
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
    this.fetchDepartment();
    this.fetchShift();
  }
}
