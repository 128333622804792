










































































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  doInterval,
  is_online,
  route_replace,
  time_format,
} from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { deviceDetailType, deviceType } from "@/api/api.types";
import { delete_device, get_device } from "@/api";
import StatusChip from "@/components/StatusChip.vue";
import ButtonTool from "@/components/ButtonTool.vue";
import { config } from "@/shared/config";
import subDomain from "@/shared/init";
// import DeviceTransfer from "./DeviceTransfer.vue";

const ConfirmDialog = () => import("@/components/ConfirmDialog.vue");

const DeviceAdd = () => import("./DeviceAdd.vue");

const DevLocalSearch = () => import("@/components/devLocalSearch.vue");

@Component({
  name: "device-index",
  components: {
    StatusChip,
    ButtonTool,
    DeviceAdd,
    ConfirmDialog,
    DevLocalSearch,
  },
})
export default class DeviceIndex extends Vue {
  /* Variables */

  time_format = time_format;

  is_online = is_online;

  /* Check if subdomain is iwk-klang */
  isIwkKlang = subDomain === "iwk-klang.";

  tableData = [] as deviceType["data"]["_embedded"]["device"];

  tableFilter = {
    search_device: this.$route.query.search_device || null,
    device_location_uuid: this.$route.query.device_location_uuid || null,
  };

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  addDevice = null as unknown as
    | {
        uuid: string;
        name: string;
        ipAddress: string;
        ipPublic: string | null;
        openedPort: number | null;
        deviceLocation: string | null;
        note: string;
      }
    | boolean;

  deleteDevice = null as unknown as string;

  transferDevice = false;

  TableHeader: DataTableHeader[] = [
    { text: "UUID", value: "uuid" },
    { text: "Name", value: "name" },
    { text: "IP Address", value: "ipAddress" },
    // { text: "IP Public", value: "ipPublic" },
    // { text: "Session", value: "session" },
    // { text: "Opened Port", value: "openedPort" },
    { text: "Device Location", value: "deviceLocation.name" },
    { text: "Status", value: "isOnline" },
    { text: "Last Heart Beat", value: "lastHeartbeat" },
    { text: "Registered Time", value: "createdAt" },
    { text: "", value: "actions", sortable: false },
  ];

  /* Computed */

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_device({ ...this.tableFilter, page: toPage });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.device;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  onAddDevice(item: deviceDetailType["data"] | null): void {
    if (!item) {
      this.addDevice = true;
    } else {
      const {
        uuid,
        name,
        ipAddress,
        ipPublic,
        openedPort,
        deviceLocation,
        note,
      } = item;
      this.addDevice = {
        uuid,
        name,
        ipAddress,
        ipPublic,
        openedPort,
        deviceLocation: deviceLocation && deviceLocation.uuid,
        note,
      };
    }
  }

  async onDelete(): Promise<void> {
    try {
      await delete_device(this.deleteDevice);
      Notif.notif_success("Device Successfully Deleted !!");
      this.fetchData();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  copyText(text: string): void {
    const temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = `${config.BASE_URL}/#/register?deviceid=${text}`;
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
  }

  private _ifIwkKlang() {
    this.TableHeader = this.TableHeader.filter(
      (head) => head.text !== "Device Location"
    );
    const obj = Object.entries(this.tableFilter).filter(
      ([k, v]) => k === "device_location_id"
    );
    this.tableFilter = Object.fromEntries(obj) as any;
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
    const title = this.$route.name;
    doInterval(this.fetchData, title);

    if (this.isIwkKlang) this._ifIwkKlang();
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
