var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"report-visitor","fluid":""}},[_c('h2',{staticClass:"font-weight-medium mb-3"},[_vm._v(_vm._s(_vm.$route.name))]),_c('v-card',{staticClass:"mb-3"},[_c('v-card-text',[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":"","color":"primary","small":""}},[_vm._v("mdi-information")]),_c('b',[_vm._v("Search by these following criteria")]),_c('v-spacer'),_c('button-tool',{attrs:{"disabled":_vm.tableData.length == 0,"icon":'mdi-pdf-box',"color":'error',"text":'Export to PDF'},on:{"open":function($event){return _vm.exportTo()}}})],1),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.fetchData()}}},[_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-col',{attrs:{"cols":"6"}},[_c('date-picker',{attrs:{"start":_vm.tableFilter.start_date,"end":_vm.tableFilter.end_date},on:{"update:start":function($event){return _vm.$set(_vm.tableFilter, "start_date", $event)},"update:end":function($event){return _vm.$set(_vm.tableFilter, "end_date", $event)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","small":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1)],1)])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.TableHeader,"items":_vm.tableData,"items-per-page":_vm.page.page_size,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.visitor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userProfile ? item.userProfile.firstName : item.visitor)+" ")]}},{key:"item.faceIn",fn:function(ref){
var item = ref.item;
return [_c('photo-avatar',{attrs:{"value":item.faceIn}})]}},{key:"item.tempIn",fn:function(ref){
var item = ref.item;
return [(item.tempIn)?_c('temp-text',{attrs:{"value":item.tempIn}}):_vm._e()]}},{key:"item.faceOut",fn:function(ref){
var item = ref.item;
return [_c('photo-avatar',{attrs:{"value":item.faceOut}})]}},{key:"item.tempOut",fn:function(ref){
var item = ref.item;
return [(item.tempOut)?_c('temp-text',{attrs:{"value":item.tempOut}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"size":'xs',"value":item.status}})]}}],null,true)}),_c('v-divider'),_c('v-pagination',{attrs:{"total-visible":"10","length":_vm.page.page_count},model:{value:(_vm.page.page),callback:function ($$v) {_vm.$set(_vm.page, "page", $$v)},expression:"page.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }