
































































































import PageModules from "@/view/PageModules";
import { Component, Vue } from "vue-property-decorator";
import NotificationBar from "@/components/NotificationBar.vue";
import { Auth, Notif } from "@/store";
import { meType } from "@/api/api.types";

type Link = {
  link: string;
  name: string;
  icon?: string;
  children?: Link[];
};

@Component({ name: "sidebar-layout", components: { NotificationBar } })
export default class SidebarLayout extends Vue {
  /* Variables */

  mini = true;

  /* Computed */

  public get drawerLink(): Link[] {
    const link = [] as Link[];
    PageModules.forEach((route) => {
      const children = route.children.filter(
        (child) => !child.path.includes(":") && !child.isHide
      );
      if (!route.rootName || !route.rootIcon) return;
      link.push({
        link: children.length > 1 ? "-" : route.rootLink,
        name: route.rootName,
        icon: route.rootIcon,
        children:
          children.length > 1
            ? children.map((child) => ({
                link: `${route.rootLink}/${child.path}`,
                name: child.name as string,
              }))
            : undefined,
      });
    });
    return link;
  }

  public get selfProfile(): meType["data"] {
    return Auth.self_profile;
  }

  /* Methods */

  async logout(): Promise<void> {
    try {
      await Auth.Logout();
      localStorage.clear();
      this.$router.replace({ name: "Login" });
      window.location.reload();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  // created(): void {
  //   if (Auth.is_Login) {
  //     Auth.GetProfile();
  //   }
  // }
}
