




































































































































import { get_alarmlog_detail } from "@/api";
import { alarmlogDetailType } from "@/api/api.types";
import { time_format } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

const PhotoAvatar = () => import("@/components/PhotoAvatar.vue");

@Component({ name: "alarmlog-detail", components: { PhotoAvatar } })
export default class AlarmlogDetail extends Vue {
  /* Variables */

  time_format = time_format;

  uuid = this.$route.params.uuid;

  detailData = {} as alarmlogDetailType["data"];

  userProfile = ({} as alarmlogDetailType["data"]["userProfile"]) || null;

  pictures = [] as alarmlogDetailType["data"]["eventInfos"];

  loading = false;

  /* Computed */
  /* Methods */

  async fetchData(): Promise<void> {
    try {
      this.loading = true;
      const resp = await get_alarmlog_detail(this.uuid);
      this.detailData = resp.data;
      const { eventInfos, userProfile } = resp.data;
      this.userProfile = userProfile;
      if (eventInfos) this.pictures = eventInfos;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  color(): string | undefined {
    if (!this.pictures) return;
    if (!this.pictures[0].similarity) return;
    const same = this.pictures[0].similarity * 100;
    if (same > 80) return "green--text";
    if (same > 50) return "primary--text";
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
  }
}
