









































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { cxcessDoorType, cxDoorDetailType } from "@/api/api.types";
import {
  delete_cxcess_door,
  get_cxcess_door,
  post_release_door,
  post_reset_schedule,
  post_users_delete_access,
} from "@/api";
import ButtonTool from "@/components/ButtonTool.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DoorAdd from "./DoorAdd.vue";

@Component({
  name: "cxcess-door",
  components: { ButtonTool, ConfirmDialog, DoorAdd },
})
export default class CxcessDoor extends Vue {
  /* Variables */

  tableData = [] as cxcessDoorType["data"]["_embedded"]["channelMapping"];

  tableFilter = {
    search: this.$route.query.search || null,
  };

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  deleteDoor = null as unknown as string;

  addDoor = null as unknown as boolean | cxDoorDetailType["data"];

  resetSchedule = null as unknown as string;

  releaseDoor = null as unknown as string;

  deleteAccess = null as unknown as string;

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Ch Num", value: "chNum" },
      { text: "Device", value: "device.name" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_cxcess_door({ ...this.tableFilter, page: toPage });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.channelMapping;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, this.tableFilter);
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async doResetSchedule(): Promise<void> {
    try {
      await post_reset_schedule({ devMapping: this.resetSchedule });
      Notif.notif_success("Schedule Ressetted.");
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async doReleaseDoor(): Promise<void> {
    try {
      await post_release_door({ devMapping: this.releaseDoor });
      Notif.notif_success("Door Released.");
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async doDelete(): Promise<void> {
    try {
      await delete_cxcess_door(this.deleteDoor);
      Notif.notif_success("Channel Mapping Successfully Deleted !!!");
      this.fetchData();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async doDeleteAccess(): Promise<void> {
    try {
      await post_users_delete_access({
        userId: "-1",
        channelMapping: this.deleteAccess,
      });
      Notif.notif_success("Access Deleted.");
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
