


















































































































































import {
  get_capture,
  get_invitation,
  get_report_attendance,
  get_setting_user,
  get_visitor,
} from "@/api";
import { captureType, invitationsType } from "@/api/api.types";
import { time_format } from "@/shared/utils";
import { Notif } from "@/store";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { ApexOptions } from "apexcharts";
import PhotoAvatar from "@/components/PhotoAvatar.vue";
import StatusChip from "@/components/StatusChip.vue";
import TempText from "@/components/TempText.vue";
import ApexCharts from "vue-apexcharts";

@Component({
  name: "dashboard-iwk",
  components: { PhotoAvatar, StatusChip, TempText, ApexCharts },
})
export default class DashboardIwk extends Vue {
  /* Variables */

  counter = [] as { icon: string; label: string; value: number }[];

  invitationData = [] as invitationsType["data"]["_embedded"]["invitation"];

  HighTempData = [] as captureType["data"]["_embedded"]["transactionSummary"];

  ChartOptions = {
    xaxis: {
      categories: ["2001/12/12", "2001/12/01"], // just a dummy
    },
  } as ApexOptions;

  BodyTempOptions = {};

  FaceCaptureChart = [
    { name: "Face Capture Total", data: [] },
  ] as ApexOptions["series"];

  HighTempChart = [
    { name: "High Temperature Total", data: [] },
  ] as ApexOptions["series"];

  LowTempChart = [
    {
      name: "Normal Temperature Total",
      data: [],
    },
  ] as ApexOptions["series"];

  BodyTempChart = [] as number[];

  /* Computed */

  public get InvitationHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name", width: "130" },
      { text: "Photo", value: "photo", sortable: false },
      { text: "Status", value: "status" },
      { text: "Created Time", value: "createdAt", width: "200" },
    ];
  }

  public get HighHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "userProfile.firstName" },
      { text: "Temp In", value: "tempIn" },
      { text: "Temp Out", value: "tempOut" },
      { text: "Status", value: "status" },
    ];
  }

  /* Methods */

  // Fetch employee counter
  async fetchEmployee(): Promise<void> {
    try {
      const resp = await get_setting_user({ role: "user" });
      this.counter.push({
        icon: "mdi-account",
        label: "Employees",
        value: resp.data.total_items,
      });
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  // Fetch Registered-Person counter
  async fetchVisitor(): Promise<void> {
    try {
      const resp = await get_visitor();
      this.counter.push({
        icon: "mdi-account-group",
        label: "Registered Person",
        value: resp.data.total_items,
      });
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  // Fetch Today-Visit counter
  async fetchVisitToday(): Promise<void> {
    try {
      const resp = await get_capture({
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      });
      this.counter.push({
        icon: "mdi-badge-account",
        label: "Today Visit",
        value: resp.data.total_items,
      });
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  // Fetch Attendance-Today counter
  async fetchAttendance(): Promise<void> {
    try {
      const resp = await get_report_attendance();
      const total = resp.data.attendance.length;
      this.counter.push({
        icon: "mdi-account-check",
        label: "Attendance Today",
        value: total,
      });
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  // Compile Counter
  doCounter(): void {
    this.fetchAttendance();
    this.fetchEmployee();
    // this.fetchVisitor();
    // this.fetchVisitToday();
  }

  // Fetch Invitation list
  async fetchInvitation(): Promise<void> {
    try {
      const resp = await get_invitation({
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      });
      this.invitationData = resp.data._embedded.invitation;
      this.invitationData.map(
        (item) => (item.createdAt = time_format(item.createdAt))
      );
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  // Fetch High-Temp list
  async fetchHighTemperature(): Promise<void> {
    try {
      const resp = await get_capture({
        high_temp_in_from: 37,
        start_date: moment().startOf("week").format("YYYY-MM-DD"),
        end_date: moment().endOf("week").format("YYYY-MM-DD"),
        limit: -1,
      });
      this.HighTempData = resp.data._embedded.transactionSummary;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  // Fetch area chart
  async fetchCaptureWeek(opt: "all" | "low" | "high"): Promise<void> {
    try {
      let high_temp_in_from;
      let low_temp_in_from;
      if (opt == "low") low_temp_in_from = 37;
      else if (opt == "high") high_temp_in_from = 37;

      /* fetch capture for a week */
      const resp = await get_capture({
        high_temp_in_from,
        low_temp_in_from,
        start_date: moment().startOf("week").format("YYYY-MM-DD"),
        end_date: moment().endOf("week").format("YYYY-MM-DD"),
        limit: -1,
      });
      const data = resp.data._embedded.transactionSummary;

      /* Set up options & series values */
      let set = [
        ...new Set(data.map((t) => time_format(t.createdAt, "YYYY/MM/DD"))),
      ].reverse();
      let count = [] as number[];
      set.forEach((it) => {
        count.push(
          data.filter((t) => time_format(t.createdAt, "YYYY/MM/DD") == it)
            .length
        );
      });

      /* Set up options and series charts*/
      this.ChartOptions = { xaxis: { categories: set } };
      if (opt == "all") {
        this.FaceCaptureChart = [{ data: count }];
      } else if (opt == "low") {
        this.LowTempChart = [{ data: count }];
      } else if (opt == "high") {
        this.HighTempChart = [{ data: count }];
      }
      if (opt !== "all") {
        if (count.length != 0) {
          this.BodyTempChart.push(
            count.reduce((t, i) => {
              return t + i;
            })
          );
        }
      }
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.doCounter();
    this.fetchInvitation();
    this.fetchHighTemperature();
    this.fetchCaptureWeek("all");
    this.fetchCaptureWeek("high");
    this.fetchCaptureWeek("low");
  }
}
