import subDomain from "./init";

export const config = {
  // Branding
  APP_NAME: process.env.VUE_APP_NAME || "Face Temperature",
  APP_TITLE: process.env.VUE_APP_TITLE || "Xtend Face Temp",
  APP_COPYRIGHT: process.env.VUE_APP_COPYRIGHT || "Xtend Face Temperature",
  APP_COPYRIGHT_URL: process.env.VUE_APP_COPYRIGHT || "",

  // API
  APP_SECRET: process.env.VUE_APP_SECRET || "d979e29f23f",
  APP_ID: process.env.VUE_APP_ID || "facetemp-admin",
  BASE_URL:
    process.env.VUE_APP_API_PROTOCOL +
    subDomain +
    process.env.VUE_APP_API_DOMAIN,

  // External Services
  GOOGLE_API_KEY:
    process.env.VUE_APP_GOOGLE_API_KEY ||
    "AIzaSyCS_gZ0HyF1YOhmWqgOtd0xX3LwrBUr7Ms",
  MOSQUE_GOOGLE_API_KEY:
    process.env.VUE_APP_MOSQ_GOOGLE_API_KEY ||
    "AIzaSyAVYz6rn39c3X9uvVrATa1e--DWRbXpqBU",

  // Firebase
  FIREBASE_CONFIG: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASU,
  },
  VAPID_KEY:
    "BP0np67MdCzjkxgsZqO3GIDwU3pB8IuqOCveNQOrqdrcQqZEB26lhfTgAWaG8x5hW2dsoYi1Dps4qY-BiV-8JQg",

  // Utility
  INTERVAL_REFRESH_DATA: process.env.VUE_APP_INTERVAL_REFRESH_DATA || 120000,
  INTERVAL_REFRESH_DATA_DASHBOARD:
    process.env.VUE_APP_INTERVAL_REFRESH_DATA_DASHBOARD || 300000,
  DATE_FORMAT: process.env.VUE_APP_DATE_FORMAT || "DD-MM-YYYY hh:mm A",
  SHORT_DATE_FORMAT: process.env.VUE_APP_SHORT_DATE_FORMAT || "DD-MM-YYYY",
};
