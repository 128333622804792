import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

type notifType = {
  type: string;
  message: string;
};
@Module({ name: "Notif" })
export default class NotifStore extends VuexModule {
  /* States */

  message = "";
  type = "";
  visible = false;

  /* Getters */

  public get is_visible(): boolean {
    return this.visible;
  }

  public get notifData(): { type: string; message: string } {
    return { type: this.type, message: this.message };
  }

  /* Mutations */

  @Mutation
  notif(notifData: { type: string; message: string; visible: boolean }): void {
    this.type = notifData.type;
    this.message = notifData.message;
  }

  @Mutation
  set_visibility(visible: boolean): void {
    this.visible = visible;
  }

  /* Actions */

  @Action({ commit: "notif" })
  notif_error(message: string): notifType {
    this.context.commit("set_visibility", true);
    return { type: "error", message };
  }

  @Action({ commit: "notif" })
  notif_info(message: string): notifType {
    this.context.commit("set_visibility", true);
    return { type: "info", message };
  }

  @Action({ commit: "notif" })
  notif_warning(message: string): notifType {
    this.context.commit("set_visibility", true);
    return { type: "warning", message };
  }

  @Action({ commit: "notif" })
  notif_success(message: string): notifType {
    this.context.commit("set_visibility", true);
    return { type: "success", message };
  }

  @Action({ commit: "notif" })
  notif_api_error(error: Record<string, any> | any): notifType {
    this.context.commit("set_visibility", true);
    const message = error.response ? error.response.data.detail : error.message;
    return { type: "error", message };
  }
}
