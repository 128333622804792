import { AppModule } from "@/shared/types";
import LoginIndex from "./index.vue";

const LoginModule: AppModule = {
  rootLink: "/login",
  rootName: "Login",
  layout: "Basic",
  authRequired: false,
  children: [
    {
      path: "",
      name: "Login",
      component: LoginIndex,
    },
  ],
};

export default LoginModule;
