const domain = window.location.host;
const splitDomain = domain.split(".");
export const subDomainList = [
  "dev",
  "digi-dev",
  "thedesk",
  "masjid-dev",
  "commissary",
  "mdn",
  "cloudkitchen",
  "dominos",
  "digi",
  "office",
  "edotco",
  "pemprovsu",
  "iwk-klang",
];
let subDomain = "mdn";
// if (splitDomain[0].indexOf("localhost") !== -1) {
//   if (subDomainList.indexOf(subDomain) === -1) {
//     subDomain = "";
//     console.log("ERROR: Subdomain not found");
//   }
// } else {
//   subDomain = splitDomain[0];
// }
// subDomain = splitDomain[0].indexOf('localhost') === -1 && (splitDomain[0].indexOf('192') === -1 && splitDomain[1].indexOf('168') === -1) ? splitDomain[0] : 'cemaraasri'

if (domain.includes(process.env.VUE_APP_API_DOMAIN)) {
  subDomain = splitDomain[0];
} else {
  if (subDomainList.indexOf(subDomain) === -1) {
    subDomain = "";
    console.log("ERROR: Subdomain not found");
  }
}

subDomain += ".";

export default subDomain;
