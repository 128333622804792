





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "photo-avatar" })
export default class PhotoAvatar extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string | null;
  @Prop({ default: 35 }) readonly size!: string | number;
  @Prop({ default: "500px" }) readonly viewSize!: string | number;
  @Prop({ default: false }) readonly tile!: boolean;
  @Prop({ default: false }) readonly preview!: boolean;

  /* Variables */

  photoView = null as unknown as string;

  /* Computed */

  public get photo(): string {
    return this.value || "/img/img-placeholder.jpg";
  }

  public get photoSize(): number | string {
    if (this.preview) return 350;
    else return this.size;
  }

  public get photoTile(): boolean {
    if (this.preview) return true;
    else return this.tile;
  }

  /* Methods */
}
