import { AppModule } from "@/shared/types";

import CxcessDevice from "./Device/index.vue";

import CxcessDoor from "./DoorMapping/index.vue";
import CxcessDoorDetail from "./DoorMapping/detail.vue";

import CxcessTransaction from "./TransactionLog/index.vue";

import CxcessUsers from "./Users/index.vue";
import CxcessUsersDetail from "./Users/detail.vue";

const CxcessModule: AppModule = {
  rootLink: "/cxcess",
  rootIcon: "mdi-fingerprint",
  rootName: "Cxcess",
  layout: "Sidebar",
  authRequired: true,
  children: [
    {
      path: "device",
      name: "Device",
      component: CxcessDevice,
    },
    {
      path: "door-mapping",
      name: "Door Mapping",
      component: CxcessDoor,
    },
    {
      path: "door-mapping/:uuid",
      name: "Door Mapping Detail",
      component: CxcessDoorDetail,
    },
    {
      path: "transaction-log",
      name: "Transaction Log",
      component: CxcessTransaction,
    },
    {
      path: "users",
      name: "Users",
      component: CxcessUsers,
    },
    {
      path: "users/:uuid",
      name: "Users Details",
      component: CxcessUsersDetail,
    },
  ],
};

export default CxcessModule;
