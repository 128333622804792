


























































import { get_visitor_detail } from "@/api";
import { visitorDetailType } from "@/api/api.types";
import { time_format } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import PhotoAvatar from "@/components/PhotoAvatar.vue";

@Component({ name: "visitor-detail", components: { PhotoAvatar } })
export default class VisitorDetail extends Vue {
  /* Variables */

  time_format = time_format;

  uuid = this.$route.params.uuid;

  detailData = {} as visitorDetailType["data"];

  /* Computed */
  /* Methods */

  async fetchData(): Promise<void> {
    try {
      const resp = await get_visitor_detail(this.uuid);
      this.detailData = resp.data;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
  }
}
