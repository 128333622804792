















import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({ name: "notification-bar" })
export default class NotificationBar extends Vue {
  /* Variables */

  /* Computed */

  public get visible(): boolean {
    return Notif.is_visible;
  }

  public set visible(v: boolean) {
    Notif.set_visibility(v);
  }

  public get notifData(): { type: string; message: string } {
    return Notif.notifData;
  }

  /* Methods */

  /* Life-cycle Methods */
}
