import { AppModule } from "@/shared/types";
import Dashboard from "./index.vue";

const DashboardModules: AppModule = {
  rootLink: "/",
  rootIcon: "mdi-view-dashboard",
  rootName: "Dashboard",
  layout: "Sidebar",
  authRequired: true,
  children: [
    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
    },
  ],
};

export default DashboardModules;
