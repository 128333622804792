import { AppModule } from "@/shared/types";
import CaptureModule from "./Capture/capture.module";
import DashboardModules from "./Dashboard/dashboard.module";
import LoginModule from "./Login/login.module";
import DeviceModule from "./Device/device.module";
import AlarmlogModule from "./AlarmLog/alarmlog.module";
import VisitorModule from "./Visitor/visitor.module";
// import InvitationModule from "./Invitation/invitation.module";
// import TelegramModule from "./Telegram/telegram.module";
import ReportModule from "./Report/report.module";
import SettingsModule from "./Settings/settings.module";
import CxcessModule from "./Cxcesss/cxcess.module";
import TransactionlogModule from "./TransactionLog/transactionlog.module";
import RegisterModule from "./Register/register.module";
import ReportIwkModule from "./Report/report-iwk.module ";
import DashboardIwkModules from "./Dashboard-iwk/dashboard-iwk.module";
import subDomain from "@/shared/init";
import { Auth } from "@/store";
import SettingsIwkModule from "./Settings/settings-iwk.module ";

let PageModules: AppModule[] = [LoginModule];

const DefaultPage: AppModule[] = [
  DashboardModules,
  CaptureModule,
  DeviceModule,
  AlarmlogModule,
  VisitorModule,
  ReportModule,
  SettingsModule,
  TransactionlogModule,
];

const SupervisorPage: AppModule[] = [
  DashboardModules,
  CaptureModule,
  AlarmlogModule,
  VisitorModule,
  ReportModule,
  SettingsModule,
];

const CxcessPage: AppModule[] = [DashboardModules, DeviceModule, CxcessModule];

const IwkPage: AppModule[] = [
  DashboardIwkModules,
  CaptureModule,
  DeviceModule,

  ReportIwkModule,
  SettingsIwkModule,
  TransactionlogModule,
];
// const DevPage: AppModule[] = [
//   DashboardModules,
//   CaptureModule,
//   DeviceModule,
//   AlarmlogModule,
//   VisitorModule,

// ];

// const MasjidDevPage: AppModule[] = [DashboardModules];

export const PageModuleList = {
  DefaultPage,
  CxcessPage,
  SupervisorPage,
  IwkPage,
};

let tempLink: AppModule[] = [];
for (const item of Object.keys(PageModuleList)) {
  const tempSubDomain = subDomain.split("-")[0];
  if (Auth.self_role == "Cxcess") {
    tempLink = PageModuleList["CxcessPage"];
  } else if (Auth.self_role == "Supervisor") {
    tempLink = PageModuleList["SupervisorPage"];
  } else if (item.toLowerCase().includes(tempSubDomain)) {
    tempLink = PageModuleList[item as keyof typeof PageModuleList];
    break;
  } else {
    tempLink = DefaultPage;
  }
}
PageModules = PageModules.concat(tempLink);

export default PageModules;
