

















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Notif } from "@/store";
import {
  settingUserDetailShiftType,
  settingUserDetailType,
  settingUserType,
  userDeviceType,
} from "@/api/api.types";
import {
  get_setting_user,
  get_setting_user_detail,
  get_user_device,
  get_user_device_detail,
  patch_user_device,
  post_user_scheduling,
  post_user_photo,
} from "@/api";
import { DataTableHeader } from "vuetify";
import { time_format } from "@/shared/utils";
import subDomain from "@/shared/init";

const PhotoAvatar = () => import("@/components/PhotoAvatar.vue");
const ButtonTool = () => import("@/components/ButtonTool.vue");
const DeviceAssign = () => import("./DeviceAssign.vue");
const ConfirmDialog = () => import("@/components/ConfirmDialog.vue");
const ChangePassword = () => import("./ChangePassword.vue");
const UserShift = () => import("./UserShift.vue");

@Component({
  name: "user-detail",
  components: {
    PhotoAvatar,
    ButtonTool,
    DeviceAssign,
    ConfirmDialog,
    ChangePassword,
    UserShift,
  },
})
export default class UserDetail extends Vue {
  /* -------------------------------- Variables ------------------------------- */

  time_format = time_format;

  isCommissary = subDomain === "commissary.";

  uuid = this.$route.params.uuid;

  detailData = {} as settingUserDetailType["data"];

  userDeviceData = [] as userDeviceType["data"]["_embedded"]["userDevice"];

  assignDevice = null as string | null;

  deleteDevice = null as null | { uuid: string; targetName: string };

  userChngPass = null as string | null;

  addShift = null as null | string;

  shiftData = [] as settingUserDetailShiftType[];

  loading = false;

  userSuperviseList = [] as settingUserType["data"]["_embedded"]["profile"];

  assignedShift = null as null | string;

  photoDialog = false;

  photoData = {
    file: null as File | null,
    path: null as string | ArrayBuffer | null,
  };

  /* -------------------------------- Computed -------------------------------- */

  public get ShiftHeaders(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Start Date", value: "startDate" },
      { text: "End Date", value: "endDate" },
      { text: "Time In", value: "timeIn" },
      { text: "Time Out", value: "timeOut" },
      { text: "Registered At", value: "createdAt" },
      { text: "Registered By", value: "createdBy" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  public get SupervisedHeaders(): DataTableHeader[] {
    return [
      { text: "Name", value: "firstName" },
      { text: "Photo", value: "photo" },
      { text: "Username", value: "username" },
      { text: "IC Number", value: "icNumber" },
      { text: "Employee ID", value: "staffId" },
      { text: "Position", value: "position" },
      { text: "Phone", value: "phone" },
      { text: "Role", value: "role" },
    ];
  }

  /* --------------------------------- Methods -------------------------------- */

  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      const resp = await get_setting_user_detail(this.uuid);
      this.detailData = resp.data;

      const temp = resp.data.userShift;
      if (temp.length) {
        this.shiftData = temp.map(({ createdAt, createdBy, shift }) => {
          return { ...shift, createdAt, createdBy };
        });
      }

      /* Fetch supervised user, if this user supervisor */
      if (this.detailData.role == "Supervisor") {
        this.fetchSuperviseUser();
      }
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchUserDevice(): Promise<void> {
    try {
      const resp = await get_user_device({ user_profile_uuid: this.uuid });
      this.userDeviceData = resp.data._embedded.userDevice;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async deviceDelete(): Promise<void> {
    try {
      if (!this.deleteDevice) return;
      this.respInterval(this.deleteDevice.uuid, "delete");
      await patch_user_device(this.deleteDevice.uuid, {
        action: "deletePerson",
        targetName: this.deleteDevice.targetName,
      });
      Notif.notif_success("Waiting for Device Response...");
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchSuperviseUser(): Promise<void> {
    try {
      const resp = await get_setting_user({
        supervisor_uuid: this.uuid,
        limit: -1,
      });
      this.userSuperviseList = resp.data._embedded.profile;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async unAssingShift(): Promise<void> {
    if (!this.assignedShift) return;
    const temp = this.shiftData
      .map((t) => t.uuid)
      .filter((t) => t !== this.assignedShift)
      .toString();
    try {
      await post_user_scheduling({
        userProfile: this.uuid,
        shifts: temp,
      });
      Notif.notif_success("Shift deleted.");
      this.fetchData();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  respInterval(uuid: string, action: "delete" | "assign"): void {
    let i = 0;
    const interval = setInterval(async () => {
      if (i > 2) clearInterval(interval);
      try {
        const resp = await get_user_device_detail(uuid);
        const { resultMessage } = resp.data;
        if (resultMessage && action == "assign") {
          clearInterval(interval);
          Notif.notif_info(resultMessage);
        } else {
          Notif.notif_error("Device Not Responding.");
        }
      } catch (error) {
        if (error && action == "delete") {
          clearInterval(interval);
          Notif.notif_info("User not exist in the device");
        }
      } finally {
        this.fetchUserDevice();
      }
      i++;
    }, 10 * 1000);
  }

  user_blank(uuid?: string): void {
    if (!uuid) return;
    const route = this.$router.resolve({
      name: "",
      params: { uuid },
    });
    window.open(route.href, "_blank");
  }

  async uploadPhoto() {
    if (!this.photoData.file) return;
    this.loading = true;
    try {
      await post_user_photo({
        userProfile: this.uuid,
        photo: this.photoData.file,
      });
      Notif.notif_info("Photo sucessfully uploaded");
      this.photoDialog = false;
      this.fetchData();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  submitPhoto() {
    const fileInput = document.body.appendChild(
      document.createElement("input")
    );
    fileInput.type = "file";
    fileInput.accept = 'accept="image/png, image/jpg, image/jpeg"';
    fileInput.onchange = (e) => {
      const img = (e.target as HTMLInputElement).files?.item(0) ?? null;
      if (img) {
        this.photoData.file = img;
        const reader = new FileReader();
        reader.onload = () => {
          this.photoData.path = reader.result;
          this.photoDialog = true;
        };
        reader.readAsDataURL(img);
        document.body.removeChild(fileInput);
      }
    };
    fileInput.click();
  }

  /* --------------------------- Life-cycle Methods --------------------------- */

  mounted(): void {
    this.fetchData();
    this.fetchUserDevice();
  }
}
