

















































































































import { VuetifyForm } from "@/shared/types";
import { Auth, Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { required, eightChar } from "@/shared/validation";
import {
  settingDepartmentType,
  settingUserDetailType,
  settingUserType,
} from "@/api/api.types";
import {
  patch_setting_user,
  get_setting_department,
  post_setting_user,
  get_setting_user,
} from "@/api";

@Component({ name: "user-add" })
export default class UserAdd extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: boolean;

  required = required;

  eightChar = eightChar;

  /* Variables */

  profileRole = Auth.self_role;

  formData = {
    firstName: "",
    icNumber: "",
    staffId: "",
    phone: "",
    department: "",
    position: "",
    role: "User",
    supervisor: null as null | string,
    externalUserId: null as null | string,
    password: "",
    isKkip: 0,
  };

  uuid = null as null | string;

  departmentList =
    [] as settingDepartmentType["data"]["_embedded"]["department"];

  roleList = ["Admin", "Supervisor", "User", "Cxcess"];

  userList = [] as settingUserType["data"]["_embedded"]["profile"];
  userLoading = false;

  /* Computed */

  /* Methods */

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      if (this.formData.externalUserId == "") {
        this.formData.externalUserId = null;
      }
      if (!this.uuid) {
        await post_setting_user(this.formData);
        Notif.notif_success("User Successfully Added!!!");
      } else {
        await patch_setting_user(this.uuid, this.formData);
        Notif.notif_success("User Successfully Editted!!!");
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    this.uuid = null;
    return null;
  }

  async fetchDepartment(): Promise<void> {
    try {
      const resp = await get_setting_department({ limit: -1 });
      this.departmentList = resp.data._embedded.department;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchUserSuper(): Promise<void> {
    this.userLoading = true;
    try {
      const resp = await get_setting_user({ role: "Supervisor", limit: -1 });
      this.userList = resp.data._embedded.profile;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.userLoading = false;
    }
  }

  /* Life-cycle Methods */

  @Watch("value")
  onValueChange(now: boolean | settingUserDetailType["data"]): void {
    /* Fetch Department */
    this.fetchDepartment();

    /* Fetch User Supervisor */
    this.fetchUserSuper();

    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        firstName: now.firstName,
        icNumber: now.icNumber,
        staffId: now.staffId ? now.staffId : "",
        phone: now.phone,
        department: now.department ? now.department.uuid : "",
        position: now.position ? now.position : "",
        role: now.role,
        supervisor: now.supervisor ? now.supervisor.uuid : null,
        externalUserId: now.externalUserId,
        password: undefined as unknown as string,
        isKkip: now.isKkip,
      };
      this.uuid = now.uuid;
    }
  }
}
