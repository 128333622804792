import { tokenEject, tokenInject } from "@/api";
import { Store } from "vuex";

const token_manager = (store: Store<any>): void => {
  let timeOut: number;

  const tokenRefresher = (
    access_token: string,
    refresh_token: string,
    expires_in: number
  ): void => {
    tokenInject(access_token);
    timeOut = setTimeout(async () => {
      store.dispatch("Refresh", refresh_token);
    }, expires_in);
  };

  if (store.getters["is_Login"]) {
    const { access_token, refresh_token } = store.getters;
    tokenRefresher(access_token, refresh_token, 1000);
  }

  store.subscribe((mutation) => {
    if (mutation.type === "setLogin") {
      if (mutation.payload !== undefined) {
        const { access_token, refresh_token, expires_in } = mutation.payload;
        tokenRefresher(access_token, refresh_token, expires_in);
      } else {
        tokenEject();
        clearTimeout(timeOut);
        localStorage.clear();
      }
    }
  });
};

export default token_manager;
