import { AppModule } from "@/shared/types";
import DeviceIndex from "./index.vue";
import DeviceDetail from "./detail.vue";

const DeviceModule: AppModule = {
  rootLink: "/device",
  rootIcon: "mdi-cellphone-link",
  rootName: "Device",
  layout: "Sidebar",
  authRequired: false,
  children: [
    {
      path: "/",
      name: "Device",
      component: DeviceIndex,
    },
    {
      path: ":uuid",
      name: "Device Detail",
      component: DeviceDetail,
    },
  ],
};

export default DeviceModule;
