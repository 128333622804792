







import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "temp-text" })
export default class TempText extends Vue {
  /* Components */

  @Prop({ required: true }) readonly value!: number | null;

  /* Methods */

  tempColor(): string | undefined {
    if (!this.value) return;
    if (this.value > 37) return "error--text";
    if (this.value < 37) return "success--text";
  }
}
