import { AppModule } from "@/shared/types";
import CaptureIndex from "./index.vue";
import CaptureDetail from "./detail.vue";

const CaptureModule: AppModule = {
  rootLink: "/capture",
  rootIcon: "mdi-camera",
  rootName: "Capture",
  layout: "Sidebar",
  authRequired: false,
  children: [
    {
      path: "/",
      name: "Capture",
      component: CaptureIndex,
    },
    {
      path: ":uuid",
      name: "Capture Detail",
      component: CaptureDetail,
    },
  ],
};

export default CaptureModule;
