
























































import { Component, Vue } from "vue-property-decorator";
import { Notif } from "@/store";
import { get_transaction_log_detail } from "@/api";
import { transactionLogDetailType } from "@/api/api.types";

import PhotoAvatar from "@/components/PhotoAvatar.vue";
import TempText from "@/components/TempText.vue";
import { time_format } from "@/shared/utils";

@Component({
  name: "transactionlog-detail",
  components: { PhotoAvatar, TempText },
})
export default class TransactionlogDetail extends Vue {
  /* Constants */

  time_format = time_format;

  /* Variables */

  uuid = this.$route.params.uuid;

  detailData = {} as transactionLogDetailType["data"];

  loading = false;

  /* Computed */
  /* Methods */

  async fetchData(): Promise<void> {
    try {
      this.loading = true;
      const resp = await get_transaction_log_detail(this.uuid);
      this.detailData = resp.data;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
  }
}
