














































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  doInterval,
  open_export,
  route_replace,
  time_format,
} from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { captureType } from "@/api/api.types";
import { get_report_visitor } from "@/api";
import PhotoAvatar from "@/components/PhotoAvatar.vue";
import TempText from "@/components/TempText.vue";
import StatusChip from "@/components/StatusChip.vue";
import DatePicker from "@/components/DatePicker.vue";
import ButtonTool from "@/components/ButtonTool.vue";
import URL from "@/api/api.constants";

@Component({
  name: "report-visitor",
  components: { PhotoAvatar, TempText, StatusChip, DatePicker, ButtonTool },
})
export default class ReportVisitor extends Vue {
  /* Variables */

  tableData = [] as captureType["data"]["_embedded"]["transactionSummary"];

  tableFilter = {
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
  };

  date = this.tableFilter.start_date
    ? [this.tableFilter.start_date, this.tableFilter.end_date]
    : [];

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "visitor" },
      { text: "Type", value: "type" },
      { text: "Photo In", value: "faceIn" },
      { text: "Device In", value: "deviceIn.name" },
      { text: "Time In", value: "inTime" },
      { text: "Temp In", value: "tempIn" },
      { text: "Photo Out", value: "faceOut" },
      { text: "Device Out", value: "deviceOut.name" },
      { text: "Time Out", value: "outTime" },
      { text: "Temp Out", value: "tempOut" },
      { text: "Status", value: "status" },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_report_visitor({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.transactionSummary;
      this.tableData.forEach((item) => {
        item.inTime = time_format(item.inTime);
        if (item.outTime) item.outTime = time_format(item.outTime);
      });
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  exportTo(): void {
    open_export(URL.CAPTURED_INFO, { type: "Visitor", ...this.tableFilter });
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
