





































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({ name: "date-picker" })
export default class DatePicker extends Vue {
  @Prop({ default: null }) readonly value!: string[] | string;

  @PropSync("start", { type: String }) start_date!: string;

  @PropSync("end", { type: String }) end_date!: string;

  @Prop({ default: "Search by Date Range" }) readonly label!: string;

  @Prop() readonly rules!: string[];

  @Prop({ default: true }) readonly range!: boolean;

  @Prop() readonly disabled!: boolean;

  private dateMenu = false;

  private get dates(): string | string[] {
    if (this.range) return this.value || [];
    else return this.value || "";
  }

  private set dates(value: string[] | string) {
    this.$emit("input", value);
  }

  private get datePick(): string | null {
    if (this.range) {
      if (this.dates && this.dates.length > 1) {
        if (this.dates[0] > this.dates[1]) {
          const test = [this.dates[0], this.dates[1]];
          (this.dates as string[]).push(test[1]);
          (this.dates as string[]).push(test[2]);
          // this.dates[0] = test[1];
          // this.dates[1] = test[0];
        }
        this.fillDate();
        return `${this.dates[0]} ~ ${this.dates[1]}`;
      } else {
        this.fillDate();
        return null;
      }
    } else {
      if (this.dates) {
        return this.dates as string;
      } else {
        return null;
      }
    }
  }

  private set datePick(value: string | null) {
    this.$emit("input", value);
  }

  fillDate(): void {
    this.start_date = this.dates[0];
    this.end_date = this.dates[1];
  }

  private dateRule(): void {
    if (this.dates.length > 1) {
      this.dateMenu = false;
    }
  }
}
