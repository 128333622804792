



































































































































import { Component, Vue } from "vue-property-decorator";
import { Notif } from "@/store";
import {
  get_cxcess_door_detail,
  get_cxcess_users_device,
  get_release_door_log,
  get_reset_door_log,
} from "@/api";
import {
  cxDoorDetailType,
  cxUsersDeviceType,
  resetDoorLog,
  rlsDoorLogType,
} from "@/api/api.types";
import { DataTableHeader } from "vuetify";
import { time_format } from "@/shared/utils";
import ToolTruncate from "@/components/ToolTruncate.vue";
import ButtonTool from "@/components/ButtonTool.vue";

type pageType = {
  page: number;
  page_count: number;
  total_items: number;
  page_size: number;
};

@Component({
  name: "cxcess-door-detail",
  components: { ToolTruncate, ButtonTool },
})
export default class CxDoorDetail extends Vue {
  /* Variables */

  uuid = this.$route.params.uuid;

  // Detail Data

  detailData = {} as cxDoorDetailType["data"];

  detailDevice = {} as cxDoorDetailType["data"]["device"];

  // Table Data

  doorLogList = [] as rlsDoorLogType["data"]["_embedded"]["releaseDoorLog"];

  resetDoorLogList =
    [] as resetDoorLog["data"]["_embedded"]["resetDoorScheduleLog"];

  deviceUsersList =
    [] as cxUsersDeviceType["data"]["_embedded"]["cmsUserDevice"];

  // Pagination

  doorlogPage = {} as pageType;

  schedulePage = {} as pageType;

  devicePage = {
    page_size: 10,
  } as pageType;

  // Loading Status

  loadRelease = false;

  loadReset = false;

  loadAccess = false;

  // Filter

  deviceFilter = null as unknown as string;

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Release Time", value: "createdAt" },
      { text: "User Profile", value: "userProfile" },
      { text: "Response", value: "returnResponse" },
      { text: "", value: "actions" },
    ];
  }

  public get DeviceUsersHeader(): DataTableHeader[] {
    return [
      { text: "User Id", value: "userId.userId" },
      { text: "Name", value: "userId.name" },
      { text: "Created At", value: "createdAt" },
      { text: "Type", value: "type" },
      { text: "Response", value: "returnResponse" },
      { text: "", value: "actions" },
    ];
  }

  /* Methods */

  async fetchData(): Promise<void> {
    try {
      const resp = await get_cxcess_door_detail(this.uuid);
      this.detailData = resp.data;
      this.detailDevice = resp.data.device;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchDoorLog(
    toPage = this.doorlogPage.page,
    limit = this.doorlogPage.page_size
  ): Promise<void> {
    this.loadRelease = true;
    try {
      const resp = await get_release_door_log({
        channel_mapping_uuid: this.uuid,
        limit: 10,
        page: toPage,
      });
      this.doorLogList = resp.data._embedded.releaseDoorLog;
      this.doorLogList.map(
        (item) => (item.createdAt = time_format(item.createdAt))
      );
      const { page, page_count, total_items, page_size } = resp.data;
      this.doorlogPage = { page, page_count, total_items, page_size };
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loadRelease = false;
    }
  }

  async fetchResetDoorLog(
    toPage = this.schedulePage.page,
    limit = this.schedulePage.page_size
  ): Promise<void> {
    this.loadReset = true;
    try {
      const resp = await get_reset_door_log({
        channel_mapping_uuid: this.uuid,
        limit: 10,
        page: toPage,
      });
      this.resetDoorLogList = resp.data._embedded.resetDoorScheduleLog;
      this.resetDoorLogList.map(
        (item) => (item.createdAt = time_format(item.createdAt))
      );
      const { page, page_count, total_items, page_size } = resp.data;
      this.schedulePage = { page, page_count, total_items, page_size };
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loadRelease = false;
    }
  }

  async fetchDeviceAccess(
    toPage = this.devicePage.page,
    limit = this.devicePage.page_size
  ): Promise<void> {
    this.loadAccess = true;
    try {
      const resp = await get_cxcess_users_device({
        cms_name: this.deviceFilter,
        channel_mapping_uuid: this.uuid,
        limit,
        page: toPage,
      });
      const { page, page_count, total_items, page_size } = resp.data;
      this.devicePage = { page, page_count, total_items, page_size };
      this.deviceUsersList = resp.data._embedded.cmsUserDevice;
      this.deviceUsersList.map((t) => (t.createdAt = time_format(t.createdAt)));
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loadAccess = false;
    }
  }

  copyText(text: string): void {
    const temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = text;
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
    this.fetchDoorLog();
    this.fetchDeviceAccess();
  }

  test(something: any) {
    console.log(something);
  }
}
