import Sidebar from "./SidebarLayout.vue";
import Basic from "./BasicLayout.vue";

const layout = {
  Sidebar,
  Basic,
};

export type layoutType = keyof typeof layout;

export default layout;
