






















































import Router from "@/router";
import { Auth, Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({ name: "login" })
export default class Login extends Vue {
  /* Variables */

  loginData = {
    username: "",
    password: "",
  };

  loading = false;

  visible = false;

  /* Computed */

  /* Methods */

  async login(): Promise<void> {
    this.loading = true;
    try {
      const resp = await Auth.Login({ ...this.loginData });
      if (resp) {
        Auth.GetProfile().then(() => {
          Router.replace({ name: "Dashboard" });
        });
      }
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  /* Life-cycle Methods */

  created(): void {
    if (Auth.is_Login) {
      Router.replace({ name: "Dashboard" });
    }
  }
}
