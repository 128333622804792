import { Auth } from "@/store";
import { NavigationGuard } from "vue-router";

const guard: NavigationGuard<Vue> = (to, from, next) => {
  const { authRequired } = to.matched[0].meta;
  const isLogin = Auth.is_Login;

  if (authRequired && !isLogin) {
    next({ name: "Login" });
  } else {
    next();
  }
};

export default guard;
