









































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace, time_format } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_device, get_transaction_log } from "@/api";
import { deviceType, transactionLogType } from "@/api/api.types";

import DatePicker from "@/components/DatePicker.vue";
import PhotoAvatar from "@/components/PhotoAvatar.vue";
import TempText from "@/components/TempText.vue";

@Component({
  name: "transactionlog-index",
  components: { DatePicker, PhotoAvatar, TempText },
})
export default class TransactinlogIndex extends Vue {
  /* Variables */

  tableData = [] as transactionLogType["data"]["_embedded"]["transactionLog"];

  tableFilter = {
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    device_uuid: this.$route.query.device_uuid || null,
  };

  date = this.$route.query.start_date
    ? [this.$route.query.start_date, this.$route.query.end_date]
    : [];

  deviceList = [] as deviceType["data"]["_embedded"]["device"];

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  edit = "";

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "userProfile.firstName" },
      { text: "Type", value: "type" },
      { text: "Photo", value: "face" },
      { text: "Device", value: "device.name" },
      { text: "Time", value: "faceCaptureTime" },
      { text: "Temp", value: "temperature" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_transaction_log({
        ...this.tableFilter,
        page: toPage,
      });
      this.tableData = resp.data._embedded.transactionLog;
      this.tableData.forEach(
        (t) => (t.faceCaptureTime = time_format(t.faceCaptureTime))
      );
      const { page, page_count, page_size } = resp.data;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchDevice(): Promise<void> {
    try {
      const resp = await get_device({ limit: -1 });
      this.deviceList = [
        { uuid: null as unknown as string, name: "All Device" } as any,
        ...resp.data._embedded.device,
      ];
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchDevice();
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
