








































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { required } from "@/shared/validation";
import { post_device_function } from "@/api";

@Component({ name: "add-function" })
export default class AddFunction extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  /* Variables */

  required = required;

  formData = {
    userType: null as unknown as string,
    event: null as unknown as string,
    uuid: null,
  };

  typeList = [
    "Employee",
    "Visitor",
    "Contractor",
    "CSC Interview",
    "HQ Interview",
    "Transporter",
  ];

  loading = false;

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      await post_device_function({ ...this.formData, device: this.value });
      Notif.notif_success("Device Function Successfully Added !!");
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = true;
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    return null;
  }

  /* Life-cycle Methods */
}
