
















































































import { get_capture_detail } from "@/api";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { captureDetailType } from "@/api/api.types";
import { time_format } from "@/shared/utils";
import PhotoAvatar from "@/components/PhotoAvatar.vue";
import TempText from "@/components/TempText.vue";
import StatusChip from "@/components/StatusChip.vue";
import html2canvas from "html2canvas";

@Component({
  name: "capture-detail",
  components: { PhotoAvatar, TempText, StatusChip },
})
export default class CaptureDetail extends Vue {
  /* Variables */

  uuid = this.$route.params.uuid;

  detailData = {} as captureDetailType["data"];

  /* Computed */
  /* Methods */

  async fetchData(): Promise<void> {
    try {
      const resp = await get_capture_detail(this.uuid);
      this.detailData = resp.data;
      this.detailData.inTime = time_format(this.detailData.inTime);
      this.detailData.outTime =
        this.detailData.outTime && time_format(this.detailData.outTime);
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  captureScreen() {
    const pageToCapture = document.getElementById("capture-detail");
    const detailData = this.detailData;
    if (!pageToCapture) return;
    html2canvas(pageToCapture, {
      useCORS: true,
    }).then(function (canvas) {
      const dataURI = canvas.toDataURL("image/jpeg");
      const a = document.body.appendChild(document.createElement("a"));

      a.href = dataURI;
      a.download = `FacetempReport_${detailData.userProfile?.firstName}_${detailData.inTime}`;
      a.click();
      canvas.remove();
      a.remove();
    });
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
  }
}
