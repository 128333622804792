











































































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import {
  delete_setting_shift,
  get_setting_department,
  get_setting_shift,
} from "@/api";
import {
  settingDepartmentType,
  settingShiftDetailType,
  settingShiftType,
} from "@/api/api.types";
import StatusChip from "@/components/StatusChip.vue";
import ShiftAdd from "./ShiftAdd.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  name: "shift-index",
  components: { StatusChip, ShiftAdd, ConfirmDialog },
})
export default class ShiftIndex extends Vue {
  /* Variables */

  tableData = [] as settingShiftType["data"]["_embedded"]["shift"];

  tableFilter = {
    name: this.$route.query.name || null,
    is_available: this.$route.query.is_available || 1,
    department_uuid: this.$route.query.department_uuid || null,
  };

  departmentList =
    [] as settingDepartmentType["data"]["_embedded"]["department"];

  availableList = [
    { value: null, text: "All" },
    { value: "1", text: "Available" },
    { value: "0", text: "Not Available" },
  ];

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  addShift = null as unknown as boolean | settingShiftDetailType["data"];

  deleteShift = null as unknown as string;

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Deparment", value: "department.name" },
      { text: "Time In", value: "timeIn" },
      { text: "Time Out", value: "timeOut" },
      { text: "Start Date", value: "startDate" },
      { text: "End Date", value: "endDate" },
      { text: "Available", value: "isAvailabel" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_setting_shift({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.shift;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchDepartment(): Promise<void> {
    try {
      const resp = await get_setting_department({ limit: -1 });
      this.departmentList = resp.data._embedded.department;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async doDeleteShift(): Promise<void> {
    try {
      await delete_setting_shift(this.deleteShift);
      Notif.notif_success("Shift Deleted.");
      this.fetchData();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
    this.fetchDepartment();
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
