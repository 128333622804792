










import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "status-checker" })
export default class StatusChecker extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string | null | boolean;

  @Prop() readonly text!: string;
}
