







































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Notif } from "@/store";
import {
  delete_device_locations,
  get_device,
  get_device_location_detail,
} from "@/api";
import { is_online, time_format } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { deviceType } from "@/api/api.types";

const StatusChip = () => import("@/components/StatusChip.vue");
const ConfirmDialog = () => import("@/components/ConfirmDialog.vue");

@Component({
  name: "device-location-detail",
  components: { StatusChip, ConfirmDialog },
})
export default class DevLocalDetail extends Vue {
  /* Constants */

  time_format = time_format;

  is_online = is_online;

  /* Variables */

  uuid = this.$route.params.uuid;

  detailData = {};

  deviceData = [] as deviceType["data"]["_embedded"]["device"];

  deleteDevLocal = null as null | string;

  devicePage = {
    total_items: 1,
    page: 1,
    page_count: 1,
  };

  deviceLoading = false;

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "UUID", value: "uuid" },
      { text: "Name", value: "name" },
      { text: "IP Address", value: "ipAddress" },
      { text: "Status", value: "isOnline" },
      { text: "Last Heart Beat", value: "lastHeartbeat" },
      { text: "Registered Time", value: "createdAt" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(): Promise<void> {
    try {
      const resp = await get_device_location_detail(this.uuid);
      this.detailData = resp.data;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchDevice(): Promise<void> {
    try {
      const resp = await get_device({
        device_location_uuid: this.uuid,
        page: this.devicePage.page,
      });
      let { total_items, page, page_count } = resp.data;
      this.deviceData = resp.data._embedded.device;
      if (page == 0) page = 1;
      this.devicePage = { total_items, page, page_count };
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async doDelete(): Promise<void> {
    try {
      this.deviceLoading = true;
      if (this.deviceData.length > 0) {
        Notif.notif_error(
          "Locations Can't be Deleted If there's still device connected."
        );
      } else {
        await delete_device_locations(this.uuid);
        Notif.notif_success("Device Location Deleted !");
        this.$router.replace({ name: "Device Locations" });
      }
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.deviceLoading = false;
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
    this.fetchDevice();
  }

  @Watch("devicePage.page")
  onDevicePageChange(): void {
    this.fetchDevice();
  }
}
