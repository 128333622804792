import { AppModule } from "@/shared/types";

import TransactionlogIndex from "./index.vue";
import TransactionlogDetail from "./detail.vue";

const TransactionlogModule: AppModule = {
  rootLink: "/transaction-log",
  rootName: "Transaction Log",
  layout: "Sidebar",
  authRequired: true,
  children: [
    {
      path: "/",
      name: "Transaction Log",
      component: TransactionlogIndex,
    },
    {
      path: ":uuid",
      name: "Transaction Log Detail",
      component: TransactionlogDetail,
    },
  ],
};

export default TransactionlogModule;
