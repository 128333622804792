import { AppModule } from "@/shared/types";
import ReportAttendance from "./Attendance/index.vue";
import ReportLateExit from "./LateExit/index.vue";

const ReportIwkModule: AppModule = {
  rootLink: "/report",
  rootIcon: "mdi-file-chart",
  rootName: "Report",
  layout: "Sidebar",
  authRequired: false,
  children: [
    {
      path: "attendance",
      name: "Attendance",
      component: ReportAttendance,
    },
    {
      path: "late-exit",
      name: "Late Exit",
      component: ReportLateExit,
    },
  ],
};

export default ReportIwkModule;
