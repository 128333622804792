





























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "button-tool" })
export default class ButtonTool extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly icon!: string;
  @Prop({ default: "Tips" }) readonly text!: string;
  @Prop({ default: "primary" }) readonly color!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: false }) readonly button!: boolean;
  @Prop() readonly size!: "xl" | "lg" | "sm" | "xs";

  /* Variables */
  /* Computed */
  /* Methods */

  something(): void {
    this.$emit("open");
  }

  /* Life-cycle Methods */
}
