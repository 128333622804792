























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Notif } from "@/store";
import {
  get_cxcess_door,
  get_cxcess_users_detail,
  get_cxcess_users_device,
  post_users_delete_access,
  post_users_record_code,
} from "@/api";
import {
  cxcessDoorType,
  cxUsersDetailType,
  cxUsersDeviceType,
} from "@/api/api.types";
import { DataTableHeader } from "vuetify";
import StatusChecker from "@/components/StatusChecker.vue";
import { time_format } from "@/shared/utils";
import ToolTruncate from "@/components/ToolTruncate.vue";
import ButtonTool from "@/components/ButtonTool.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

const UsersDevice = () => import("./UsersDevice.vue");
@Component({
  name: "cxcess-users-detail",
  components: {
    StatusChecker,
    UsersDevice,
    ToolTruncate,
    ButtonTool,
    ConfirmDialog,
  },
})
export default class CxUserDetail extends Vue {
  /* Variables */

  uuid = this.$route.params.uuid;

  detailData = {} as cxUsersDetailType["data"];

  deviceData = [] as cxUsersDeviceType["data"]["_embedded"]["cmsUserDevice"];

  deviceLoading = false;

  editCard = false;

  editPin = false;

  filterDoor = null as unknown as string;

  formEdit = {
    cardNum: null as unknown as string,
    pin: null as unknown as string,
  };

  DeleteAccess = null as unknown as string;

  AssignDevice = null as {
    id: string;
    type: "device" | "fingerprint1" | "fingerprint2";
  } | null;

  typeList = [] as string[];

  doorList = [] as cxcessDoorType["data"]["_embedded"]["channelMapping"];

  deviceTotal = 1;

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Channel Mapping", value: "channelMapping" },
      { text: "Type", value: "type" },
      { text: "Updated At", value: "updatedAt" },
      { text: "Response", value: "returnResponse" },
      { text: "", value: "actions" },
    ];
  }

  public get delAccessCons(): boolean {
    return !this.filterDoor || this.deviceData.length == 0;
  }

  /* Methods */

  time_format = time_format;

  sixChar = (value: string): boolean | string =>
    (value && value.length >= 6) || "Min 6 Character";

  async fetchData(): Promise<void> {
    try {
      const resp = await get_cxcess_users_detail(this.uuid);
      this.detailData = resp.data;
      const test = ["cardNum", "pin", "fingerprint1", "fingerprint2"];
      this.typeList = [];
      test.forEach((t) => {
        const s =
          this.detailData[t as keyof typeof CxUserDetail.prototype.detailData];
        this.typeList.push("fingerprint1");
        if (s) {
          if (t == "cardNum") {
            this.typeList.push("card");
          } else if (t == "pin") {
            this.typeList.push("password");
          } else {
            this.typeList.push(t);
          }
        }
      });
      this.fetchDevice();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchDevice(toPage = 1): Promise<void> {
    this.deviceLoading = true;
    try {
      const resp = await get_cxcess_users_device({
        userId: this.detailData.cmsUserId,
        channel_mapping_uuid: this.filterDoor,
        limit: 10,
        page: toPage,
      });
      this.deviceData = resp.data._embedded.cmsUserDevice;
      this.deviceData.map((t) => (t.updatedAt = time_format(t.updatedAt)));
      this.deviceTotal = resp.data.total_items;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.deviceLoading = false;
    }
  }

  async fetchDoor(): Promise<void> {
    try {
      const resp = await get_cxcess_door({ limit: -1 });
      this.doorList = resp.data._embedded.channelMapping;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async doRecordCode(type: string): Promise<void> {
    try {
      const code = type == "card" ? this.formEdit.cardNum : this.formEdit.pin;
      const form = {
        userId: this.detailData.cmsUserId,
        type: type,
        code: code,
      };
      await post_users_record_code(form);
      Notif.notif_success(type + "successfully updated.");
      this.editPin = this.editCard = false;
      this.fetchData();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  onAssign(type: "device" | "fingerprint1" | "fingerprint2"): void {
    this.AssignDevice = { id: this.detailData.cmsUserId, type: type };
  }

  copyText(text: string): void {
    const temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = text;
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
  }

  async doDeleteAccess(): Promise<void> {
    try {
      await post_users_delete_access({
        userId: this.detailData.cmsUserId,
        channelMapping: this.DeleteAccess,
      });
      Notif.notif_success("Access Successfully Deleted.");
      this.fetchDevice();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
    this.fetchDoor();
  }

  @Watch("filterDoor")
  onFilterDoorChange(): void {
    this.fetchDevice();
  }
}
