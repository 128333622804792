








































































































































































import { Auth, Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace, time_format } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_capture, get_device } from "@/api";
import { captureDetailType, captureType, deviceType } from "@/api/api.types";
import PhotoAvatar from "@/components/PhotoAvatar.vue";
import DateRange from "@/components/DatePicker.vue";
import StatusChip from "@/components/StatusChip.vue";
import TempText from "@/components/TempText.vue";

const FormCapture = () => import("./FormCapture.vue");

@Component({
  name: "capture-index",
  components: { PhotoAvatar, DateRange, StatusChip, TempText, FormCapture },
})
export default class CaptureIndex extends Vue {
  /* Variables */

  tableData = [] as captureType["data"]["_embedded"]["transactionSummary"];

  tableFilter = {
    search_user: this.$route.query.search_user || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    type: this.$route.query.type || null,
    status: this.$route.query.status || null,
    device_in_uuid: this.$route.query.device_in_uuid || null,
    device_out_uuid: this.$route.query.device_out_uuid || null,
    high_temp_in_from: this.$route.query.high_temp_in_from || null,
    high_temp_out_from: this.$route.query.high_temp_out_from || null,
    low_temp_in_from: this.$route.query.low_temp_in_from || null,
    low_temp_out_from: this.$route.query.low_temp_out_from || null,
  };

  loading = false;

  date = this.tableFilter.start_date
    ? [this.tableFilter.start_date, this.tableFilter.end_date]
    : [];

  temp = {
    in: null as unknown as string,
    out: null as unknown as string,
  };

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  typeList = [
    "Employee",
    "Visitor",
    "Contractor",
    "CSC Interview",
    "HQ Interview",
    "Transporter",
  ];

  statusList = ["IN", "OUT"];

  deviceList = [] as deviceType["data"]["_embedded"]["device"];

  tempList = [
    { value: "l37", text: "< 37" },
    { value: "h37", text: "> 37" },
  ];

  formTransaction = false as boolean | captureDetailType["data"];

  role = Auth.self_role;

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "userProfile.firstName" },
      { text: "Photo In", value: "faceIn" },
      { text: "Device In", value: "deviceIn.name" },
      { text: "Time In", value: "inTime" },
      { text: "Temp In", value: "tempIn" },
      { text: "Photo Out", value: "faceOut" },
      { text: "Device Out", value: "deviceOut.name" },
      { text: "Time Out", value: "outTime" },
      { text: "Temp Out", value: "tempOut" },
      { text: "Status", value: "status" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  get restriction(): boolean {
    return this.role == "Supervisor" || this.role == "Admin";
  }

  /* Methods */

  filterOption(): void {
    /* date */
    if (this.date?.length > 1) {
      this.tableFilter.start_date = this.date[0];
      this.tableFilter.end_date = this.date[1];
    } else {
      this.tableFilter.start_date = null;
      this.tableFilter.end_date = null;
    }

    /* temp */
    if (this.temp.in == "l37") {
      this.tableFilter.low_temp_in_from = "37";
      this.tableFilter.high_temp_in_from = null;
    } else if (this.temp.in == "h37") {
      this.tableFilter.low_temp_in_from = null;
      this.tableFilter.high_temp_in_from = "37";
    } else {
      this.tableFilter.high_temp_in_from = null;
      this.tableFilter.low_temp_in_from = null;
    }
    if (this.temp.out == "l37") {
      this.tableFilter.low_temp_out_from = "37";
      this.tableFilter.high_temp_out_from = null;
    } else if (this.temp.out == "h37") {
      this.tableFilter.low_temp_out_from = null;
      this.tableFilter.high_temp_out_from = "37";
    } else {
      this.tableFilter.high_temp_out_from = null;
      this.tableFilter.low_temp_out_from = null;
    }
  }

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      this.filterOption();
      const resp = await get_capture({ ...this.tableFilter, page: toPage });
      this.tableData = resp.data._embedded.transactionSummary;
      this.tableData.forEach((item) => {
        item.inTime = time_format(item.inTime);
        if (item.outTime) item.outTime = time_format(item.outTime);
      });
      const { page_count, page_size, page } = resp.data;
      this.page = { page_count, page_size, page };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchDevice(): Promise<void> {
    try {
      const resp = await get_device({ limit: 100 });
      this.deviceList = resp.data._embedded.device;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
    this.fetchDevice();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
