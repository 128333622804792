





































import { patch_setting_department, post_setting_department } from "@/api";
import { settingDepartmentType } from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { required } from "@/shared/validation";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

type deparmentItem =
  settingDepartmentType["data"]["_embedded"]["department"][0];
@Component({ name: "department-add" })
export default class DepartmentAdd extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  required = required;

  /* Variables */

  formData = {
    name: "",
    note: "",
  };

  uuid = null as string | null;

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      if (!this.uuid) {
        await post_setting_department(this.formData);
        Notif.notif_success("Department Successfully Added!!!");
      } else {
        await patch_setting_department(this.uuid, this.formData);
        Notif.notif_success("Department Successfully Editted!!!");
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    this.uuid = null;
    return null;
  }

  /* Life-cycle Methods */

  @Watch("value")
  onValueChange(now: boolean | deparmentItem): void {
    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        name: now.name,
        note: now.note,
      };
      this.uuid = now.uuid;
    }
  }
}
