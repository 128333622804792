import { AppModule } from "@/shared/types";
import AlarmlogIndex from "./index.vue";
import AlarmlogDetail from "./detail.vue";

const AlarmlogModule: AppModule = {
  rootLink: "/alarm-log",
  rootIcon: "mdi-alarm-light",
  rootName: "Alarm Log",
  layout: "Sidebar",
  authRequired: true,
  children: [
    {
      path: "/",
      name: "Alarm Log",
      component: AlarmlogIndex,
    },
    {
      path: ":uuid",
      name: "Alarm Log Detail",
      component: AlarmlogDetail,
    },
  ],
};

export default AlarmlogModule;
