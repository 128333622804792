













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "status-chip" })
export default class StatusChip extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;
  @Prop({ default: "sm" }) readonly size!: "xl" | "lg" | "sm" | "xs";

  /* Methods */
  getColor(): string | undefined {
    let text;
    if (this.value) text = this.value.toLowerCase();
    if (
      text == "in" ||
      text == "online" ||
      text == "available" ||
      text == "approved"
    )
      return "success";
    if (text == "out") return "warning";
    if (text == "invited") return "primary";
    if (text == "registered") return "secondary";
    if (text == "offline" || text == "not available") return "error";
  }
}
