
































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  doInterval,
  is_online,
  route_replace,
  time_format,
} from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_cxcess_device } from "@/api";
import { cxcessDeviceType } from "@/api/api.types";
import StatusChip from "@/components/StatusChip.vue";

@Component({ name: "Something1", components: { StatusChip } })
export default class Something2 extends Vue {
  /* Variables */

  tableData = [] as cxcessDeviceType["data"]["_embedded"]["cmsDevice"];

  tableFilter = {
    search_name: this.$route.query.search_name || null,
  };

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  edit = "";

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Device ID", value: "deviceId" },
      { text: "Name", value: "name" },
      { text: "Address", value: "address" },
      { text: "Status", value: "status" },
      { text: "Last Online", value: "lastOnline" },
    ];
  }

  /* Methods */

  is_online = is_online;

  time_format = time_format;

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_cxcess_device({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.cmsDevice;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, this.tableFilter);
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
