const URL = {
  /* Authentication */

  AUTH: "oauth",
  REVOKE: "api/v1/authrevoke",
  ME: "api/me",

  /* Endpoint */
  /* Capture */
  CAPTURE: "api/v1/transaction-summary",

  /* Device */
  DEVICE: "api/v1/device",
  DEVICE_FUNCTION: "api/v1/device-function",
  DEVICE_TRANSFER: "api/v1/device-transfer",
  DEVICE_LOCATIONS: "api/v1/device-location",
  RPC_DEVICE_USER: "api/v1/rpc-user-device",

  /* Alarm Log */
  ALARMLOG: "api/v1/ipc-event",
  ALARMlOG_CURSOR: "api/v1/ipc-event-cursor",

  /* Visitor */
  VISITOR: "api/v1/visitor",

  /* Invitation */
  INVITATION: "api/v1/invitation",

  /* Telegram */
  TELEGRAM: "api/v1/telegram-notification",

  /* Report */
  ATTENDANCE: "/api/v1/attendance",

  /* Settings */
  USER: "api/v1/profile",
  USER_DEVICE: "api/v1/user-device",
  USER_SCHEDULING: "/api/v1/shift-scheduling",
  DEPARTMENT: "api/v1/department",
  SHIFT: "api/v1/shift",
  SHIFT_SCHEDULING: "/api/v1/shift-user-scheduling",
  CHANGE_PASS: "api/v1/change-password-by-admin",

  /* Export */
  CAPTURED_INFO: "api/v1/export-captured-info",

  /* Cxcess */
  CXCESS_DEVICE: "api/v1/cms-local-device",
  CXCESS_DOOR: "api/v1/channel-mapping",
  RELEASE_DOOR_LOG: "api/v1/release-door-log",
  RELEASE_DOOR: "api/v1/cms-releasing-door",
  RESET_DOOR_LOG: "api/v1/reset-door-schedule-log",
  RESET_SCEHEDULE: "api/v1/cms-reset-fp-schedule",
  CXTRANSACTION_LOG: "api/v1/cms-local-transaction-log",
  CXCESS_USERS: "api/v1/cms-local-users",
  CXCESS_USERS_DEVICE: "api/v1/cms-local-user-device",
  USERS_ADD_DEVICE: "api/v1/cms-add-user-device",
  USERS_RECORD_FP: "api/v1/cms-record-fingerprint",
  USERS_RECORD_CODE: "api/v1/cms-record-card-password",
  USERS_DELETE_ACCESS: "api/v1/cms-del-user-device",

  /* Transaction Log */
  TRANSACTION_LOG: "api/v1/transaction-log",

  /* Others */
  DIGI_USER: "api/v1/employee-photo-submit",
};

export default URL;
