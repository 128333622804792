import axios, { AxiosResponse } from "axios";
import { config } from "@/shared/config";
import URL from "./api.constants";
import * as types from "./api.types";

/* Create api */

export const API = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/* Token */

let interceptor = null as unknown as number;

/* Token - Eject */

export const tokenEject = (): void => {
  if (!interceptor) return;
  API.interceptors.request.eject(interceptor);
  interceptor = null as unknown as number;
};

/* Token - Inject */

export const tokenInject = (access_token: string): void => {
  if (!access_token) return;
  tokenEject();
  interceptor = API.interceptors.request.use((req) => {
    req.headers.Authorization = `Bearer ${access_token}`;
    return req;
  });
};

/* API - Auth */

/* Auth - Login */

export const authentication = async (
  username: string,
  password: string
): Promise<types.authType> => {
  const data = {
    username,
    password,
    grant_type: "password",
    client_id: config.APP_ID,
    client_secret: config.APP_SECRET,
  };
  console.log([
    config.BASE_URL,
    process.env.VUE_APP_API_PROTOCOL,
    process.env.VUE_APP_API_DOMAIN,
  ]);
  const resp: types.authType = await API.post(URL.AUTH, data);
  return resp;
};

/* Auth - Refresh */

export const refresh_token_api = async (
  refresh_token: string
): Promise<types.authType> => {
  const data = {
    refresh_token,
    grant_type: "refresh_token",
    client_id: config.APP_ID,
    client_secret: config.APP_SECRET,
  };
  const resp: types.authType = await API.post(URL.AUTH, data);
  return resp;
};

/* Auth - Logout */

export const revoke = async (
  access_token: string
): Promise<AxiosResponse<any>> => {
  const data = {
    token_type_hint: "access_token",
    token: access_token,
  };
  const resp = await API.post(URL.REVOKE, data);
  return resp;
};

/* Auth - Profile */

export const get_profile = async (): Promise<types.meType> => {
  const resp: types.meType = await API.get(URL.ME);
  return resp;
};

/* Captures */

export const get_capture = async (
  params?: Record<string, any>
): Promise<types.captureType> => {
  const resp: types.captureType = await API.get(URL.CAPTURE, {
    params: params,
  });
  return resp;
};

export const get_capture_detail = async (
  uuid: string
): Promise<types.captureDetailType> => {
  const resp: types.captureDetailType = await API.get(URL.CAPTURE + "/" + uuid);
  return resp;
};

export const post_capture = async (
  formData: types.transactionLogForm
): Promise<types.captureDetailType> => {
  const resp: types.captureDetailType = await API.post(URL.CAPTURE, formData);
  return resp;
};

export const patch_capture = async (
  uuid: string,
  formData: types.transactionLogForm
): Promise<types.captureDetailType> => {
  const resp: types.captureDetailType = await API.patch(
    URL.CAPTURE + "/" + uuid,
    formData
  );
  return resp;
};

/* Device */

export const get_device = async (
  params?: Record<string, any>
): Promise<types.deviceType> => {
  const resp: types.deviceType = await API.get(URL.DEVICE, { params: params });
  return resp;
};

export const get_device_detail = async (
  uuid: string
): Promise<types.deviceDetailType> => {
  const resp: types.deviceDetailType = await API.get(URL.DEVICE + "/" + uuid);
  return resp;
};

export const patch_device = async (form: {
  uuid: string;
  name: string;
  ipAddress: string;
  note: string;
}): Promise<types.deviceDetailType> => {
  const resp: types.deviceDetailType = await API.patch(
    URL.DEVICE + "/" + form.uuid,
    form
  );
  return resp;
};

export const post_device = async (form: {
  uuid: string;
  name: string;
  ipAddress: string;
  note: string;
}): Promise<types.deviceDetailType> => {
  const resp: types.deviceDetailType = await API.post(URL.DEVICE, form);
  return resp;
};

export const delete_device = async (uuid: string): Promise<void> => {
  await API.delete(URL.DEVICE + "/" + uuid);
};

export const get_device_function = async (
  params?: Record<string, any>
): Promise<types.deviceFunctionType> => {
  const resp: types.deviceFunctionType = await API.get(URL.DEVICE_FUNCTION, {
    params: params,
  });
  return resp;
};

export const post_device_function = async (form: {
  userType: string;
  event: string;
  device: string;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.DEVICE_FUNCTION, form);
  return resp;
};

export const patch_device_function = async (
  uuid: string,
  form: { type: string; event: string; device: string }
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.DEVICE_FUNCTION + "/" + uuid, form);
  return resp;
};

export const post_device_transfer = async (form: {
  oldDevice: string;
  newDevice: string;
  note: string;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.DEVICE_TRANSFER, form);
  return resp;
};

export const get_device_locations = async (
  params?: Record<string, any>
): Promise<types.deviceLocationsType> => {
  const resp: types.deviceLocationsType = await API.get(URL.DEVICE_LOCATIONS, {
    params,
  });
  return resp;
};

export const get_device_location_detail = async (
  uuid: string
): Promise<types.devLocalDetailType> => {
  const resp: types.devLocalDetailType = await API.get(
    URL.DEVICE_LOCATIONS + "/" + uuid
  );
  return resp;
};

export const post_device_locations = async (formData: {
  name: string;
  telegramChatId: string | null;
  note: string | null;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.DEVICE_LOCATIONS, formData);
  return resp;
};

export const patch_device_locations = async (
  uuid: string,
  formData: {
    name: string;
    telegramChatId: string | null;
    note: string | null;
  }
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.DEVICE_LOCATIONS + "/" + uuid, formData);
  return resp;
};

export const delete_device_locations = async (
  uuid: string
): Promise<AxiosResponse<any>> => {
  const resp = await API.delete(URL.DEVICE_LOCATIONS + "/" + uuid);
  return resp;
};

export const get_rpc_device = async (params: {
  visitor_uuid: string;
  device_uuid: string;
}): Promise<types.rpcDeviceType> => {
  const resp: types.rpcDeviceType = await API.get(URL.RPC_DEVICE_USER, {
    params,
  });
  return resp;
};

/* Alarm Log */

export const get_alarmlog = async (
  params?: Record<string, any>
): Promise<types.alarmlogType> => {
  const resp: types.alarmlogType = await API.get(URL.ALARMLOG, {
    params: params,
  });
  return resp;
};

export const get_alarmlog_detail = async (
  uuid: string
): Promise<types.alarmlogDetailType> => {
  const resp: types.alarmlogDetailType = await API.get(
    URL.ALARMLOG + "/" + uuid
  );
  return resp;
};

export const get_alarmlog_cursor = async (
  params?: Record<string, any>
): Promise<types.alarmlogCursorType> => {
  const resp: types.alarmlogCursorType = await API.get(URL.ALARMlOG_CURSOR, {
    params,
  });
  return resp;
};

/* Visitor */

export const get_visitor = async (
  params?: Record<string, any>
): Promise<types.visitorType> => {
  const resp: types.visitorType = await API.get(URL.VISITOR, {
    params: params,
  });
  return resp;
};

export const post_visitor = async (
  formData: FormData
): Promise<types.visitorDetailType> => {
  const resp: types.visitorDetailType = await API.post(URL.VISITOR, formData);
  return resp;
};

export const get_visitor_detail = async (
  uuid: string
): Promise<types.visitorDetailType> => {
  const resp: types.visitorDetailType = await API.get(URL.VISITOR + "/" + uuid);
  return resp;
};

/* Invitation */

export const get_invitation = async (
  params?: Record<string, any>
): Promise<types.invitationsType> => {
  const resp: types.invitationsType = await API.get(URL.INVITATION, {
    params: params,
  });
  return resp;
};

/* Telegram */

export const get_telegram = async (
  params?: Record<string, any>
): Promise<types.telegramType> => {
  const resp: types.telegramType = await API.get(URL.TELEGRAM, {
    params: params,
  });
  return resp;
};

export const post_telegram = async (form: {
  event: string;
  telegramGroupId: string;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.TELEGRAM, form);
  return resp;
};

export const patch_telegram = async (
  uuid: string,
  form: {
    event: string;
    telegramGroupId: string;
  }
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.TELEGRAM + "/" + uuid, form);
  return resp;
};

/* Report */

export const get_report_attendance = async (
  params?: Record<string, any>
): Promise<types.reportAttendanceType> => {
  const resp: types.reportAttendanceType = await API.get(URL.ATTENDANCE, {
    params: params,
  });
  return resp;
};

export const get_report_visitor = async (
  params?: Record<string, any>
): Promise<types.captureType> => {
  const resp: types.captureType = await API.get(URL.CAPTURE, {
    params: { type: "visitor", ...params },
  });
  return resp;
};

export const get_report_csc = async (
  params?: Record<string, any>
): Promise<types.captureType> => {
  const resp: types.captureType = await API.get(URL.CAPTURE, {
    params: { type: "CSC", ...params },
  });
  return resp;
};

export const get_report_hq = async (
  params?: Record<string, any>
): Promise<types.captureType> => {
  const resp: types.captureType = await API.get(URL.CAPTURE, {
    params: { type: "HQ", ...params },
  });
  return resp;
};

export const get_report_transporter = async (
  params?: Record<string, any>
): Promise<types.captureType> => {
  const resp: types.captureType = await API.get(URL.CAPTURE, {
    params: { type: "Transporter", ...params },
  });
  return resp;
};

export const get_report_contractor = async (
  params?: Record<string, any>
): Promise<types.captureType> => {
  const resp: types.captureType = await API.get(URL.CAPTURE, {
    params: { type: "Contractor", ...params },
  });
  return resp;
};

export const get_report_lateexit = async (
  params?: Record<string, any>
): Promise<types.captureType> => {
  const resp: types.captureType = await API.get(URL.CAPTURE, {
    params: { is_late: 1, ...params },
  });
  return resp;
};

/* Settings */

/* Settings - User */

export const get_setting_user = async (
  params?: Record<string, any>
): Promise<types.settingUserType> => {
  const resp: types.settingUserType = await API.get(URL.USER, {
    params: params,
  });
  return resp;
};

export const get_setting_user_detail = async (
  uuid: string
): Promise<types.settingUserDetailType> => {
  const resp: types.settingUserDetailType = await API.get(
    URL.USER + "/" + uuid
  );
  return resp;
};

export const post_setting_user = async (
  form: types.settingUserForm
): Promise<types.settingUserDetailType> => {
  const resp: types.settingUserDetailType = await API.post(URL.USER, form);
  return resp;
};

export const patch_setting_user = async (
  uuid: string,
  form:
    | types.settingUserForm
    | { shift?: string; noShift?: string; isActive?: number }
): Promise<types.settingUserDetailType> => {
  const resp: types.settingUserDetailType = await API.patch(
    URL.USER + "/" + uuid,
    form
  );
  return resp;
};

export const get_user_device = async (
  params: Record<string, any>
): Promise<types.userDeviceType> => {
  const resp: types.userDeviceType = await API.get(URL.USER_DEVICE, { params });
  return resp;
};

export const get_user_device_detail = async (
  uuid: string
): Promise<types.userDeviceDetailType> => {
  const resp: types.userDeviceDetailType = await API.get(
    URL.USER_DEVICE + "/" + uuid
  );
  return resp;
};

export const post_user_device = async (
  form: Record<"action" | "device" | "userProfile" | "targetName", string>
): Promise<types.userDeviceDetailType> => {
  const resp: types.userDeviceDetailType = await API.post(
    URL.USER_DEVICE,
    form
  );
  return resp;
};

export const patch_user_device = async (
  uuid: string,
  form: {
    action: string;
    device?: string;
    userProfile?: string;
    targetName: string;
  }
): Promise<types.userDeviceDetailType> => {
  const resp: types.userDeviceDetailType = await API.patch(
    URL.USER_DEVICE + "/" + uuid,
    form
  );
  return resp;
};

export const post_change_pass = async (
  formData: Record<"user" | "newPassword" | "confirmPassword", string>
): Promise<AxiosResponse<any>> => {
  return await API.post(URL.CHANGE_PASS, formData);
};

export const post_user_scheduling = async (formData: {
  userProfile: string;
  shifts: string;
}): Promise<types.settingUserDetailType> => {
  const resp: types.settingUserDetailType = await API.post(
    URL.USER_SCHEDULING,
    formData
  );
  return resp;
};

export const post_user_photo = async (formData: {
  userProfile: string;
  photo: File;
}): Promise<AxiosResponse<any>> => {
  const forms = new FormData();
  Object.entries(formData).forEach(([k, v]) => {
    if (v) forms.append(k, v);
  });
  const req = await API.post("/api/v1/employee-photo-submit", forms);
  return req;
};

/* Settings - Department */

export const get_setting_department = async (
  params?: Record<string, any>
): Promise<types.settingDepartmentType> => {
  const resp: types.settingDepartmentType = await API.get(URL.DEPARTMENT, {
    params: params,
  });
  return resp;
};

export const post_setting_department = async (form: {
  name: string;
  note: string;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.DEPARTMENT, form);
  return resp;
};

export const patch_setting_department = async (
  uuid: string,
  form: { name: string; note: string }
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.DEPARTMENT + "/" + uuid, form);
  return resp;
};

/* Settings - Shift */

export const get_setting_shift = async (
  params?: Record<string, any>
): Promise<types.settingShiftType> => {
  const resp: types.settingShiftType = await API.get(URL.SHIFT, {
    params: params,
  });
  return resp;
};

export const get_setting_shift_detail = async (
  uuid: string
): Promise<types.settingShiftDetailType> => {
  const resp: types.settingShiftDetailType = await API.get(
    URL.SHIFT + "/" + uuid
  );
  return resp;
};

export const post_setting_shift = async (
  form: types.settingShiftForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.SHIFT, form);
  return resp;
};

export const patch_setting_shift = async (
  uuid: string,
  form: types.settingShiftForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.SHIFT + "/" + uuid, form);
  return resp;
};

export const delete_setting_shift = async (
  uuid: string
): Promise<AxiosResponse<any>> => {
  const resp = await API.delete(URL.SHIFT + "/" + uuid);
  return resp;
};

export const post_shift_scheduling = async (formData: {
  shift: string;
  users: string;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.SHIFT_SCHEDULING, formData);
  return resp;
};

/* Cxcess */

/* Cxcess - Device */

export const get_cxcess_device = async (
  params?: Record<string, any>
): Promise<types.cxcessDeviceType> => {
  const resp: types.cxcessDeviceType = await API.get(URL.CXCESS_DEVICE, {
    params: params,
  });
  return resp;
};

/* Cxcess - Door */

export const get_cxcess_door = async (
  params?: Record<string, any>
): Promise<types.cxcessDoorType> => {
  const resp: types.cxcessDoorType = await API.get(URL.CXCESS_DOOR, {
    params: params,
  });
  return resp;
};

export const get_cxcess_door_detail = async (
  uuid: string
): Promise<types.cxDoorDetailType> => {
  const resp: types.cxDoorDetailType = await API.get(
    URL.CXCESS_DOOR + "/" + uuid
  );
  return resp;
};

export const post_cxcess_door = async (
  form: types.cxcessDoorForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.CXCESS_DOOR, form);
  return resp;
};

export const patch_cxcess_door = async (
  uuid: string,
  form: types.cxcessDoorForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.CXCESS_DOOR + "/" + uuid, form);
  return resp;
};

export const delete_cxcess_door = async (
  uuid: string
): Promise<AxiosResponse<any>> => {
  const resp = await API.delete(URL.CXCESS_DOOR + "/" + uuid);
  return resp;
};

export const get_release_door_log = async (
  params?: Record<string, any>
): Promise<types.rlsDoorLogType> => {
  const resp: types.rlsDoorLogType = await API.get(URL.RELEASE_DOOR_LOG, {
    params: params,
  });
  return resp;
};

export const post_release_door = async (form: {
  devMapping: string;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.RELEASE_DOOR, form);
  return resp;
};

export const get_reset_door_log = async (
  params?: Record<string, any>
): Promise<types.resetDoorLog> => {
  const resp: types.resetDoorLog = await API.get(URL.RESET_DOOR_LOG, {
    params: params,
  });
  return resp;
};

export const post_reset_schedule = async (form: {
  devMapping: string;
}): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.RESET_SCEHEDULE, form);
  return resp;
};

/* Cxcess -Transaction Log */

export const get_cxcess_transactionlog = async (
  params?: Record<string, any>
): Promise<types.cxTransactionLogType> => {
  const resp: types.cxTransactionLogType = await API.get(
    URL.CXTRANSACTION_LOG,
    {
      params: params,
    }
  );
  return resp;
};

/* Cxcess - Users */

export const get_cxcess_users = async (
  params?: Record<string, any>
): Promise<types.cxcessUsersType> => {
  const resp: types.cxcessUsersType = await API.get(URL.CXCESS_USERS, {
    params: params,
  });
  return resp;
};

export const get_cxcess_users_detail = async (
  uuid: string
): Promise<types.cxUsersDetailType> => {
  const resp: types.cxUsersDetailType = await API.get(
    URL.CXCESS_USERS + "/" + uuid
  );
  return resp;
};

export const get_cxcess_users_device = async (
  params?: Record<string, any>
): Promise<types.cxUsersDeviceType> => {
  const resp: types.cxUsersDeviceType = await API.get(URL.CXCESS_USERS_DEVICE, {
    params: params,
  });
  return resp;
};

export const post_users_add_device = async (
  form: types.cxUsersAddFormType
): Promise<types.cxUsersAddDeviceType> => {
  const resp: types.cxUsersAddDeviceType = await API.post(
    URL.USERS_ADD_DEVICE,
    form
  );
  return resp;
};

export const post_users_record_fp = async (
  form: types.cxUsersAddFormType
): Promise<types.cxUsersRecordFP> => {
  const resp: types.cxUsersRecordFP = await API.post(URL.USERS_RECORD_FP, form);
  return resp;
};

export const post_users_record_code = async (form: {
  userId: string;
  type: string;
  code: string;
}): Promise<types.cxUsersRecordCode> => {
  const resp: types.cxUsersRecordCode = await API.post(
    URL.USERS_RECORD_CODE,
    form
  );
  return resp;
};

export const post_users_delete_access = async (form: {
  userId: string;
  channelMapping: string;
}): Promise<types.cxUsersDeleteAccess> => {
  const resp: types.cxUsersDeleteAccess = await API.post(
    URL.USERS_DELETE_ACCESS,
    form
  );
  return resp;
};

/* Trasaction Log */

export const get_transaction_log = async (
  params?: Record<string, any>
): Promise<types.transactionLogType> => {
  const resp = await API.get(URL.TRANSACTION_LOG, { params: params });
  return resp;
};

export const get_transaction_log_detail = async (
  uuid: string
): Promise<types.transactionLogDetailType> => {
  const resp = await API.get(URL.TRANSACTION_LOG + "/" + uuid);
  return resp;
};
