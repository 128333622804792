import { AppModule } from "@/shared/types";
import VisitorIndex from "./index.vue";
import VisitorDetail from "./detail.vue";

const VisitorModule: AppModule = {
  rootLink: "/visitor",
  rootIcon: "mdi-account-group",
  rootName: "Visitor",
  layout: "Sidebar",
  authRequired: false,
  children: [
    {
      path: "/",
      name: "Visitor",
      component: VisitorIndex,
    },
    {
      path: ":uuid",
      name: "Visitor Detail",
      component: VisitorDetail,
    },
  ],
};

export default VisitorModule;
