import Vue from "vue";
import layout from "@/layout";
import PageModules from "@/view/PageModules";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: RouteConfig[] = [];

PageModules.forEach((route) => {
  // const something = module.map((route) => (
  //     {
  //         path: route.rootLink,
  //         component: layout[route.layout],
  //         children: route.children.map(item => item)
  //     } as RouteConfig
  // ))

  routes.push({
    path: route.rootLink,
    component: layout[route.layout],
    meta: { authRequired: route.authRequired || false },
    children: route.children.map((item) => item),
  } as RouteConfig);
  // routes.push(...something)
});

const Router = new VueRouter({
  // mode: "history",
  base: "",
  routes,
});

export default Router;
