































































































import { Component, Vue } from "vue-property-decorator";
import { Notif } from "@/store";
import { get_device_detail, get_device_function } from "@/api";
import { deviceDetailType, deviceFunctionType } from "@/api/api.types";
import { is_online, time_format } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import StatusChip from "@/components/StatusChip.vue";
import AddFunction from "./AddFunction.vue";
import subDomain from "@/shared/init";

@Component({ name: "device-detail", components: { StatusChip, AddFunction } })
export default class DeviceDetail extends Vue {
  /* Variables */

  isIwkKlang = subDomain === "iwk-klang.";

  time_format = time_format;

  uuid = this.$route.params.uuid;

  detailData = {} as deviceDetailType["data"];

  deviceFuncData =
    [] as deviceFunctionType["data"]["_embedded"]["deviceFunction"];

  statusData = "";

  addFunc = null as unknown as string;

  loading = false;

  /* Computed */

  public get deviceFuncHeader(): DataTableHeader[] {
    return [
      { text: "Type", value: "userType" },
      { text: "Event", value: "event" },
      { text: "Created", value: "createdAt" },
    ];
  }

  /* Methods */

  async fetchData(): Promise<void> {
    try {
      const resp = await get_device_detail(this.uuid);
      this.detailData = resp.data;
      this.statusData = is_online(resp.data.lastHeartbeat);
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchDeviceFunction(): Promise<void> {
    this.loading = true;
    try {
      const resp = await get_device_function({ device_uuid: this.uuid });
      this.deviceFuncData = resp.data._embedded.deviceFunction;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
    this.fetchDeviceFunction();
  }
}
