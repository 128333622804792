var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"cxcess-door-detail","fluid":""}},[_c('v-btn',{staticClass:"mb-3",attrs:{"text":"","x-large":"","color":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_c('h2',{staticClass:"font-weight-medium mb-3"},[_vm._v(_vm._s(_vm.$route.name))]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"subtitle-2 primary--text"},[_vm._v("Name")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.detailData.name))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"subtitle-2 primary--text"},[_vm._v("Device Id")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.detailDevice.deviceId))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"subtitle-2 primary--text"},[_vm._v("Device Name")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.detailDevice.name))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"subtitle-2 primary--text"},[_vm._v("Channel Number")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.detailData.chNum))])])],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-medium"},[_vm._v("Release Door Log")]),_c('v-data-table',{attrs:{"headers":_vm.TableHeader,"items":_vm.doorLogList,"loading":_vm.loadRelease,"server-items-length":_vm.doorlogPage.total_items,"dense":""},on:{"update:page":function (page) { return _vm.fetchDoorLog(page); },"update:items-per-page":function (limit) { return _vm.fetchDoorLog(1, limit); }},scopedSlots:_vm._u([{key:"item.returnResponse",fn:function(ref){
var item = ref.item;
return [_c('tool-truncate',{attrs:{"value":item.returnResponse,"length":400}})]}}],null,true)}),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-medium"},[_vm._v("Reset Schedule Log")]),_c('v-data-table',{attrs:{"headers":_vm.TableHeader,"items":_vm.resetDoorLogList,"loading":_vm.loadReset,"server-items-length":_vm.schedulePage.total_items,"dense":""},on:{"update:page":function (page) { return _vm.fetchResetDoorLog(page); },"update:items-per-page":function (limit) { return _vm.fetchResetDoorLog(1, limit); }},scopedSlots:_vm._u([{key:"item.returnResponse",fn:function(ref){
var item = ref.item;
return [_c('tool-truncate',{attrs:{"value":item.returnResponse,"length":400}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tool',{attrs:{"icon":'mdi-content-copy',"color":'secondary',"size":'sm',"text":'Copy response'},on:{"open":function($event){return _vm.copyText(item.returnResponse)}}})]}}],null,true)}),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-medium"},[_vm._v("User Access")]),_c('v-form',{staticClass:"d-flex align-center col-3 px-0",on:{"submit":function($event){$event.preventDefault();return _vm.fetchDeviceAccess(1)}}},[_c('v-text-field',{attrs:{"label":"Search by Name","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.deviceFilter),callback:function ($$v) {_vm.deviceFilter=$$v},expression:"deviceFilter"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"type":"submit","color":"primary"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.DeviceUsersHeader,"items":_vm.deviceUsersList,"loading":_vm.loadAccess,"server-items-length":_vm.devicePage.total_items,"dense":""},on:{"update:page":function (page) { return _vm.fetchDeviceAccess(page); },"update:items-per-page":function (limit) { return _vm.fetchDeviceAccess(1, limit); }},scopedSlots:_vm._u([{key:"item.returnResponse",fn:function(ref){
var item = ref.item;
return [_c('tool-truncate',{attrs:{"value":item.returnResponse,"length":400}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tool',{attrs:{"icon":'mdi-content-copy',"color":'secondary',"size":'sm',"text":'Copy response'},on:{"open":function($event){return _vm.copyText(item.returnResponse)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }