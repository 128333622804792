import router from "@/router";
import moment from "moment-timezone";
import { Auth } from "@/store";
import { config } from "./config";

/**
 * Adding query to
 * the path
 * @param time string
 * @param format string
 */
export const route_replace = (
  path: string,
  query: Record<string, any>
): void => {
  Object.keys(query).forEach((key) => {
    if (query[key] == null || query[key] == "") {
      query[key] = undefined;
    }
  });
  router.replace({ path, query: query }).catch((error) => {
    if (error.name != "NavigationDuplicated") {
      throw error;
    }
  });
};

/**
 * Change time format,
 * depends on timezone
 * @param time string
 * @param format string
 */
export const time_format = (time: string, format?: string): string => {
  if (!format) format = config.DATE_FORMAT;

  return moment(time).tz(Auth.self_profile.timezone).format(format);
  // return moment(time).format(format);
};

/**
 * For export link
 * @param href string
 * @param filter any
 */
export const open_export = (
  href: string,
  filter?: Record<string, any>
): void => {
  const basUrl = config.BASE_URL.split("https:")[1];
  const url = router.resolve({
    path: basUrl + href,
    query: { token: Auth.access_token, ...filter },
  }).route.fullPath;
  window.open(url.toString(), "_blank");
};

/**
 * Detect if it's online
 * @param lastHeartbeat
 * @returns string
 */
export const is_online = (
  lastHeartbeat: string | null
): "Offline" | "Online" => {
  if (lastHeartbeat) {
    return Date.parse(lastHeartbeat) + 900000 < Date.now()
      ? "Offline"
      : "Online";
  } else {
    return "Offline";
  }
};

/**
 * Get params
 * out of url
 * @param url string
 * @param param string
 * @returns string
 */
export const getParam = (
  url: string | undefined,
  param: string
): string | null => {
  const temp_url = url ? url.split("?")[1] : url;
  const temp = new URLSearchParams(temp_url).get(param);
  return temp;
};

/**
 * Set Interval for fetch function
 * Stop interval when page closed
 * @param test string
 * @param routeName string
 */
export const doInterval = (
  fetchFunction: (toPage?: number) => Promise<void>,
  routeName: string | null | undefined
): void => {
  const interval = setInterval(() => {
    if (router.currentRoute.name !== routeName) clearInterval(interval);
    fetchFunction();
  }, config.INTERVAL_REFRESH_DATA);
};
