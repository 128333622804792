
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "Example" })
export default class Example extends Vue {
  /* Constant */

  @Prop({ required: true }) readonly value!: string;

  @Prop({ default: 110 }) readonly length!: number;
}
