































































































































import { Component, Vue } from "vue-property-decorator";
import { Notif } from "@/store";
import StatusChip from "@/components/StatusChip.vue";
import { get_setting_shift_detail, post_shift_scheduling } from "@/api";
import {
  settingShiftDetailType,
  settingShiftDetailUserType,
} from "@/api/api.types";
import { DataTableHeader } from "vuetify";
import { time_format } from "@/shared/utils";

const UserShift = () => import("./UserShift.vue");
const ConfirmDialog = () => import("@/components/ConfirmDialog.vue");
const PhotoAvatar = () => import("@/components/PhotoAvatar.vue");

@Component({
  name: "shift-detail",
  components: { StatusChip, UserShift, ConfirmDialog, PhotoAvatar },
})
export default class ShiftDetail extends Vue {
  /* Variables */

  time_format = time_format;

  uuid = this.$route.params.uuid;

  search = "";

  detailData = {} as settingShiftDetailType["data"];

  userShift = [] as settingShiftDetailUserType[];

  userTableLoading = false;

  assignUser = null as null | string;

  assignedUser = null as null | string;

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "firstName" },
      // { text: "Photo", value: "photo" },
      { text: "Staff ID", value: "staffId" },
      { text: "Phone", value: "phone" },
      { text: "Deparment", value: "department" },
      { text: "Role", value: "role" },
      { text: "Registered At", value: "createdAt" },
      { text: "Registered By", value: "createdBy" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(): Promise<void> {
    this.userTableLoading = true;
    try {
      const resp = await get_setting_shift_detail(this.uuid);
      this.detailData = resp.data;
      // this.userShift = resp.data.users || [];

      const temp = resp.data.userShift;
      if (temp.length) {
        this.userShift = temp.map(({ createdAt, createdBy, userProfile }) => {
          return { ...userProfile, createdAt, createdBy };
        });
      }
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.userTableLoading = false;
    }
  }

  async unAssignUser(): Promise<void> {
    if (!this.assignedUser) return;
    const users = this.userShift
      .map((t) => t.uuid)
      .filter((f) => f !== this.assignedUser)
      .toString();
    try {
      await post_shift_scheduling({
        shift: this.uuid,
        users,
      });
      Notif.notif_success("User unassigned.");
      this.fetchData();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
  }
}
