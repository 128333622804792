
































































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace, time_format } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_cxcess_users } from "@/api";
import { cxcessUsersType } from "@/api/api.types";
import StatusChecker from "@/components/StatusChecker.vue";

@Component({ name: "cxcess-user", components: { StatusChecker } })
export default class CxcessUser extends Vue {
  /* Variables */

  tableData = [] as cxcessUsersType["data"]["_embedded"]["cmsUsers"];

  tableFilter = {
    name: this.$route.query.name || null,
    userId: this.$route.query.userId || null,
    username: this.$route.query.username || null,
    type: this.$route.query.type || null,
  };

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  edit = "";

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "User ID", value: "cmsUserId" },
      { text: "Name", value: "cmsName" },
      {
        text: "Card Number",
        value: "cardNum",
        sortable: false,
        align: "center",
      },
      { text: "Pin", value: "pin", sortable: false, align: "center" },
      { text: "FP 1", value: "fingerprint1", sortable: false, align: "center" },
      { text: "FP 2", value: "fingerprint2", sortable: false, align: "center" },
      { text: "Expired At", value: "expiresIn" },
      { text: "Created At", value: "createdAt" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_cxcess_users({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.cmsUsers;
      this.tableData.forEach((t) => {
        if (t.expiresIn) t.expiresIn = time_format(t.expiresIn);
        t.createdAt = time_format(t.createdAt);
      });
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, this.tableFilter);
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  statCheck(text: boolean | string | null): "✓" | "-" {
    return text ? `✓` : "-";
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
