import Vue from "vue";
import Vuex from "vuex";
import AuthStore from "./modules/auth.store";
import NotifStore from "./modules/notif.store";
import token_manager from "./plugin/token_manager";
import CreatePresisted from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { AuthStore, NotifStore },
  plugins: [CreatePresisted(), token_manager],
});
