import { AppModule } from "@/shared/types";
import ReportAttendance from "./Attendance/index.vue";
import ReportVisitor from "./Visitor/index.vue";
import ReportCSCInterview from "./CSCInterview/index.vue";
import ReportHqInterview from "./HqhInterview/index.vue";
import ReportTransporter from "./Transporter/index.vue";
import ReportContractor from "./Contractor/index.vue";
import ReportLateExit from "./LateExit/index.vue";

const ReportModule: AppModule = {
  rootLink: "/report",
  rootIcon: "mdi-file-chart",
  rootName: "Report",
  layout: "Sidebar",
  authRequired: false,
  children: [
    {
      path: "attendance",
      name: "Attendance",
      component: ReportAttendance,
    },
    {
      path: "visitor",
      name: "Visitor",
      component: ReportVisitor,
    },
    {
      path: "csc-interview",
      name: "CSC Interview",
      component: ReportCSCInterview,
    },
    {
      path: "hq-interview",
      name: "HQ Interview",
      component: ReportHqInterview,
    },
    {
      path: "tranporter",
      name: "Transporter",
      component: ReportTransporter,
    },
    {
      path: "contractor",
      name: "Contractor",
      component: ReportContractor,
    },
    {
      path: "late-exit",
      name: "Late Exit",
      component: ReportLateExit,
    },
  ],
};

export default ReportModule;
