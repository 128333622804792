








































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "time-picker" })
export default class TimePicker extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  @Prop({ default: "Time picker" }) readonly label!: string;

  @Prop({ default: 1 }) readonly mStep!: number;

  @Prop() readonly rules!: string[];

  /* Variables */

  menu2 = false;

  /* Computed */

  public get time(): string {
    return this.value;
  }

  public set time(v: string) {
    this.$emit("input", v);
  }

  /* Methods */

  mStepRule = (m: number): boolean => {
    return m % this.mStep === 0;
  };
}
