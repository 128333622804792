














































import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({ name: "export-filter" })
export default class ExportFilter extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: boolean;

  @PropSync("filter", { type: Array }) filterData!: string[];

  /* Variables */

  filterList = [
    { value: "name", text: "Name" },
    { value: "department", text: "Department" },
    { value: "summaryDate", text: "Summary Date" },
    { value: "locationIn", text: "Location In" },
    { value: "shiftName", text: "Shift Name" },
    { value: "shiftTimeIn", text: "Shift Time In" },
    { value: "timeIn", text: "Time In" },
    { value: "temperatureIn", text: "Temperature In" },
    { value: "locationOut", text: "Location Out" },
    { value: "shiftTimeOut", text: "Shift Time Out" },
    { value: "timeOut", text: "Time Out" },
    { value: "temperatureOut", text: "Temperature Out" },
    { value: "late", text: "Late" },
    { value: "over", text: "Overtime" },
    { value: "hours", text: "Hours" },
  ];

  filterAll = false;

  /* Computed */
  /* Methods */

  submit(): void {
    this.$emit("export");
    this.close();
  }

  selectAll(): void {
    if (!this.filterAll) {
      this.filterData = [];
    } else {
      this.filterData = this.filterList.map((item) => item.value);
    }
  }

  @Emit("input")
  close(): null {
    return null;
  }

  /* Life-cycle Methods */
}
