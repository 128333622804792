




















































import { get_cxcess_device, patch_cxcess_door, post_cxcess_door } from "@/api";
import { cxcessDeviceType, cxDoorDetailType } from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { required } from "@/shared/validation";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "door-add" })
export default class DoorAdd extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: boolean | cxDoorDetailType["data"];

  /* Variables */

  formData = {
    name: "",
    chNum: "",
    note: "",
    device: "",
  };

  deviceList = [] as cxcessDeviceType["data"]["_embedded"]["cmsDevice"];

  uuid = null as null | string;

  /* Computed */
  /* Methods */

  required = required;

  async fetchDevice(): Promise<void> {
    try {
      const resp = await get_cxcess_device({ limit: -1 });
      this.deviceList = resp.data._embedded.cmsDevice;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      if (!this.uuid) {
        await post_cxcess_door(this.formData);
        Notif.notif_success("Channel Mapping Successfully Added!!!");
      } else {
        await patch_cxcess_door(this.uuid, this.formData);
        Notif.notif_success("Channel Mapping Successfully Editted!!!");
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    return null;
  }

  /* Life-cycle Methods */

  @Watch("value")
  onValueChange(now: boolean | cxDoorDetailType["data"]): void {
    if (now == true) {
      this.fetchDevice();
      this.uuid = null;
    } else if (now) {
      this.fetchDevice();
      this.uuid = now.uuid;
      this.formData = {
        name: now.name,
        chNum: now.chNum,
        note: now.note,
        device: now.device.uuid,
      };
    }
  }
}
