
































































































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  doInterval,
  getParam,
  route_replace,
  time_format,
} from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_alarmlog_cursor, get_device } from "@/api";
import { alarmlogCursorType, deviceType } from "@/api/api.types";
import DatePicker from "@/components/DatePicker.vue";

const DevLocalSearch = () => import("@/components/devLocalSearch.vue");
const PhotoAvatar = () => import("@/components/PhotoAvatar.vue");

@Component({
  name: "alarmlog-index",
  components: { DatePicker, DevLocalSearch, PhotoAvatar },
})
export default class AlarmlogIndex extends Vue {
  /* Variables */

  tableData = [] as alarmlogCursorType["data"]["_embedded"]["ipcEvent"];

  tableFilter = {
    name: this.$route.query.name || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    device_uuid: this.$route.query.device_uuid || null,
    event_type: this.$route.query.event_type || null,
    device_location_uuid: this.$route.query.device_location_uuid || null,
  };

  date = this.tableFilter.start_date
    ? [this.tableFilter.start_date, this.tableFilter.end_date]
    : [];

  /* Device Filter */

  deviceList = [] as deviceType["data"]["_embedded"]["device"];

  deviceSearch = null as unknown as string;

  deviceLoading = false;

  /* Device Filter - End */

  eventList = [
    { value: "alarm", text: "Alarm" },
    { value: "ElectronicFence", text: "Electronic Fence" },
    { value: "FaceCompare", text: "Face Compare" },
    { value: "FaceCapture", text: "Face Capture" },
  ];

  loading = false;

  page = {
    next: null as string | null,
    prev: null as string | null,
    cursor: this.$route.query.cursor || null,
  };

  time_format = time_format;

  edit = "";

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Device", value: "device.name" },
      // { text: "Channel", value: "channel" },
      { text: "Event", value: "eventType" },
      { text: "Photo", value: "eventInfos" },
      { text: "User Name", value: "name" },
      { text: "Device Location", value: "deviceLocation.name" },
      { text: "Time", value: "localTime" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  filterConfig(): void {
    if (this.date?.length > 1) {
      this.tableFilter.start_date = this.date[0];
      this.tableFilter.end_date = this.date[1];
    } else {
      this.tableFilter.start_date = null;
      this.tableFilter.end_date = null;
    }
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      const resp = await get_alarmlog_cursor({
        ...this.tableFilter,
        cursor: this.page.cursor,
      });
      this.tableData = resp.data._embedded.ipcEvent;
      this.page.next = getParam(resp.data._links.next?.href, "cursor");
      this.page.prev = getParam(resp.data._links.prev?.href, "cursor");
      route_replace(this.$route.path, {
        ...this.tableFilter,
        cursor: this.page.cursor,
      });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchDevice(): Promise<void> {
    try {
      this.deviceLoading = true;
      const resp = await get_device({
        search_device: this.deviceSearch,
        order: "name",
        ascending: 1,
        limit: -1,
      });
      this.deviceList = resp.data._embedded.device;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.deviceLoading = false;
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
    this.fetchDevice();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.cursor")
  onCursorChange(now: string): void {
    if (now) this.fetchData();
  }

  @Watch("deviceSearch")
  onDeviceSearch(now: string): void {
    if (now == "") this.deviceSearch = null as unknown as string;
    this.fetchDevice();
  }
}
