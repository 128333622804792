





































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { doInterval, route_replace } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_setting_department } from "@/api";
import { settingDepartmentType } from "@/api/api.types";
import DepartmentAdd from "./DepartmentAdd.vue";

type departmentTable = settingDepartmentType["data"]["_embedded"]["department"];
@Component({ name: "department-index", components: { DepartmentAdd } })
export default class DepartmentIndex extends Vue {
  /* Variables */

  tableData = [] as departmentTable;

  tableFilter = {
    search: this.$route.query.search || null,
  };

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  addDepartment = null as unknown as boolean | departmentTable[0];

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Note", value: "note" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_setting_department();
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.department;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, this.tableFilter);
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
