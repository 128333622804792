import { AppModule } from "@/shared/types";

import SettingUser from "./User/index.vue";
import SettingUserDetail from "./User/detail.vue";

import SettingDepartment from "./Department/index.vue";

import SettingShift from "./Shift/index.vue";
import SettingShiftDetail from "./Shift/detail.vue";

import DevLocIndex from "./DeviceLocations/index.vue";
import DevLocDetail from "./DeviceLocations/detail.vue";
import { Auth } from "@/store";

const SettingsModule: AppModule = {
  rootLink: "/settings",
  rootIcon: "mdi-cog",
  rootName: "Settings",
  layout: "Sidebar",
  authRequired: false,
  children: [
    {
      path: "user",
      name: "User",
      component: SettingUser,
    },
    {
      path: "user/:uuid",
      name: "User Detail",
      component: SettingUserDetail,
    },
    {
      path: "department",
      name: "Department",
      component: SettingDepartment,
      isHide: Auth.self_role == "Supervisor",
    },
    {
      path: "shift",
      name: "Shift",
      component: SettingShift,
    },
    {
      path: "shift/:uuid",
      name: "Shift Detail",
      component: SettingShiftDetail,
    },
    {
      path: "device-locations",
      name: "Device Locations",
      component: DevLocIndex,
      isHide: Auth.self_role == "Supervisor",
    },
    {
      path: "device-locations/:uuid",
      name: "Device Location Detail",
      component: DevLocDetail,
      isHide: Auth.self_role == "Supervisor",
    },
  ],
};

export default SettingsModule;
