































































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  doInterval,
  open_export,
  route_replace,
  time_format,
} from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { get_cxcess_door, get_cxcess_transactionlog } from "@/api";
import { cxcessDoorType, cxTransactionLogType } from "@/api/api.types";
import DatePicker from "@/components/DatePicker.vue";

const ButtonTool = () => import("@/components/ButtonTool.vue");

@Component({
  name: "cxcess-transaction",
  components: { DatePicker, ButtonTool },
})
export default class CxcessTransaction extends Vue {
  /* Variables */

  tableData =
    [] as cxTransactionLogType["data"]["_embedded"]["cmsTransactionLog"];

  tableFilter = {
    username: this.$route.query.username || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    dev_mapping_uuid: this.$route.query.dev_mapping_uuid || null,
    type: this.$route.query.type || null,
  };

  date = this.tableFilter.start_date
    ? [this.tableFilter.start_date, this.tableFilter.end_date]
    : [];

  doorList = [] as cxcessDoorType["data"]["_embedded"]["channelMapping"];

  typeList = [
    { value: "9899", text: "Door Open" },
    { value: "9970", text: "Door Request" },
    { value: "9898", text: "Door Lock Failure" },
  ];

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  /* Computed */

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "ID", value: "nId" },
      { text: "Time", value: "strAlarmTime" },
      { text: "User", value: "userName" },
      { text: "Alarm Type", value: "strAlarmType" },
    ];
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_cxcess_transactionlog({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.cmsTransactionLog;
      this.tableData.map(
        (item) => (item.strAlarmTime = time_format(item.strAlarmTime))
      );
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchDoor(): Promise<void> {
    try {
      const resp = await get_cxcess_door({ limit: -1 });
      this.doorList = resp.data._embedded.channelMapping;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  doExport(): void {
    open_export("/api/v1/cms-export-trx-log", this.$route.query);
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    this.fetchDoor();
    const title = this.$route.name;
    doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
